import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
    CreateButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Tooltip, Divider, Button, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';

import { ITransactionsActivityLog } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
const { Title, Text } = Typography;

export const TransactionsActivityLogsList: React.FC<IResourceComponentsProps> = () => {
    const CheckboxGroup = Checkbox.Group;
    const [searchValue, setSearchValue] = useState<string|undefined>()
    const [finalSearchValue, setFinalSearchValue] = useState<string|undefined>()

    const all_possible_regions = [
        {
            "label": "us-west-2",
            "value": "us-west-2"
        },
        {
            "label": "us-east-1",
            "value": "us-east-1"
        },
        {
            "label": "eu-central-1",
            "value": "eu-central-1"
        },
        {
            "label": "eu-west-1",
            "value": "eu-west-1"
        },
    ]

    const plainOptions = all_possible_regions
    const defaultCheckedList = ["us-west-2"] // all_possible_regions.map(a => a.value);

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
    const [searchCheckedList, setSearchCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);

    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
    
    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
    };
    
    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? plainOptions.map(a => a.value) : []);
    };
    
    const {
        user,
        } = useAuth0();
        
        const { tableProps, tableQueryResult } = useTable<ITransactionsActivityLog>({
        resource: "transactions-activity-logs",
        meta: { 
            "id": finalSearchValue,
            "regions": searchCheckedList,
        },
    });

    const refreshTableResults = () => {     
        setFinalSearchValue(searchValue)
        setSearchCheckedList(checkedList)   
        tableQueryResult.refetch()
    };

    return (

        <List headerButtons={({ createButtonProps }) => (
            <>
              {createButtonProps && <CreateButton {...createButtonProps} meta={{ foo: "bar" }} />}
            </>
          )} >
            <Title level={5}>Search regions for activity logs:</Title>
            <Divider />
            <Space.Compact size="large">
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                Check all
            </Checkbox>
                <Text>
                {
                    <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                }    
                </Text> 
            </Space.Compact>
            <Divider />
            <Title level={5}>Search work item id (can be starts with):</Title>
            <Input addonBefore={<SearchOutlined />} placeholder="Enter the search for work item id" value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
            <Divider />
            <Space.Compact style={{ width: '100%' }}>
            <Button type="primary" onClick={refreshTableResults} disabled={tableQueryResult?.isFetching }>Submit</Button>
            </Space.Compact>
            <Divider />
            <Table {...tableProps} rowKey="lock_value" dataSource={tableQueryResult?.data?.data }
                        pagination={{
                            defaultPageSize:500,
                            showSizeChanger:true,
                        }}
            >
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.lock_value - b.lock_value}
                    title="Id" 
                    dataIndex="lock_value" 
                    render={(_: any, record: { lock_value: any; pk: string; }) => {
                        return (
                            <Tooltip title={record?.lock_value}>
                            <TagField
                                value={`${
                                    record?.pk.substring(0,2)
                                }` }
                            />
                            </Tooltip>
                        );
                    }}
                />
                {/* <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.aws_step_functions_execution_id - b.aws_step_functions_execution_id}
                    filters={tableQueryResult?.data?.data.map((
                        { aws_step_functions_execution_id }) => ({ "text": aws_step_functions_execution_id, "value": aws_step_functions_execution_id})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { aws_step_functions_execution_id: string; }) => record.aws_step_functions_execution_id.startsWith(value)}
                    title="Stepfunction Execution Id" 
                    dataIndex="aws_step_functions_execution_id" 
                    render={(_: any, record: { aws_step_functions_execution_id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.aws_step_functions_execution_id
                                }` }
                            />
                        );
                    }}
                />  */}
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.org_id - b.org_id}
                    filters={tableQueryResult?.data?.data.map((
                        { org_id }) => ({ "text": org_id, "value": org_id})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { org_id: string; }) => record.org_id.startsWith(value)}
                    title="Org Id" 
                    dataIndex="org_id" 
                    render={(_: any, record: { org_id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.org_id
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.tenant_id - b.tenant_id}
                    filters={tableQueryResult?.data?.data.map((
                        { tenant_id }) => ({ "text": tenant_id, "value": tenant_id})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { tenant_id: string; }) => record.tenant_id.startsWith(value)}
                    title="Tenant Id" 
                    dataIndex="tenant_id" 
                    render={(_: any, record: { tenant_id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.tenant_id
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.message_type - b.message_type}
                    filters={tableQueryResult?.data?.data.map((
                        { message_type }) => ({ "text": message_type, "value": message_type})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { message_type: string; }) => record.message_type.startsWith(value)}
                    title="Type" 
                    dataIndex="message_type" 
                    render={(_: any, record: { message_type: any; }) => {
                        let colors = {
                            "default": "lightgreen",
                            "include": "green",
                        }

                        return (
                            <TagField
                                color={colors[record.message_type as keyof typeof colors ]}
                                value={`${
                                    record?.message_type
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.chain - b.chain}
                    filters={tableQueryResult?.data?.data.map((
                        { chain }) => ({ "text": chain, "value": chain})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { chain: string; }) => record.chain.startsWith(value)}
                    title="Chain" 
                    dataIndex="chain" 
                    render={(_: any, record: { chain: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.chain
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.address - b.address}
                    filters={tableQueryResult?.data?.data.map((
                        { address }) => ({ "text": address, "value": address})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { address: string; }) => record.address.startsWith(value)}
                    title="Address" 
                    dataIndex="address" 
                    render={(_: any, record: { address: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.address
                                }` }
                            />
                        );
                    }}
                />    
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.hash - b.hash}
                    filters={tableQueryResult?.data?.data.map((
                        { hash }) => ({ "text": hash, "value": hash})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { hash: string; }) => record.hash.startsWith(value)}
                    title="Hash" 
                    dataIndex="hash" 
                    render={(_: any, record: { hash: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.hash
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.region - b.region}
                    filters={tableQueryResult?.data?.data.map((
                        { region }) => ({ "text": region, "value": region})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { region: string; }) => record.region.startsWith(value)}
                    title="Region" 
                    dataIndex="region" 
                    render={(_: any, record: { region: string; }) => {
                        let colors = {
                            "us-west-2": "green",
                            "us-east-1": "lightgreen",
                            "eu-central-1": "blue",
                            "eu-west-1": "lightblue"
                        }
                        return (
                            <TagField
                                color={colors[record.region as keyof typeof colors ]}
                                value={`${
                                    record?.region
                                }` }
                            />
                        );
                    }}
                />      
                {/* <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.create_in_lock_table_timestamp - b.create_in_lock_table_timestamp}
                    filters={tableQueryResult?.data?.data.map((
                        { create_in_lock_table_timestamp }) => ({ "text": create_in_lock_table_timestamp, "value": create_in_lock_table_timestamp})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { create_in_lock_table_timestamp: string; }) => record.create_in_lock_table_timestamp.startsWith(value)}
                    title="Created in lock table" 
                    dataIndex="create_in_lock_table_timestamp" 
                    render={(_: any, record: { create_in_lock_table_timestamp: any; }) => {
                        return (
                            <DateField value={dayjs.unix(Number(record?.create_in_lock_table_timestamp/1000) || 1)} format="LLL"></DateField>
                        );
                    }}
                />  */}
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.stage - b.stage}
                    filters={tableQueryResult?.data?.data.map((
                        { stage }) => ({ "text": stage, "value": stage})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { stage: string; }) => record.stage.startsWith(value)}
                    title="Stage" 
                    dataIndex="stage" 
                    render={(_: any, record: { stage: any; }) => {
                        let colors = {
                            "pre_process": "lightgreen",
                            "process": "green",
                            "post_process": "darkgreen",
                        }

                        return (
                            <TagField
                                color={colors[record.stage as keyof typeof colors ]}
                                value={`${
                                    record?.stage
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.new_status_value - b.new_status_value}
                    filters={tableQueryResult?.data?.data.map((
                        { new_status_value }) => ({ "text": new_status_value, "value": new_status_value})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { new_status_value: string; }) => record.new_status_value.startsWith(value)}
                    title="New Status" 
                    dataIndex="new_status_value" 
                    render={(_: any, record: { new_status_value: any; }) => {
                        let colors = {
                            "to_be_deleted": "darkgreen",
                            "completed": "darkgreen",
                            "ready_to_be_processed": "blue",
                            "in_progress": "green",
                            "UNKNOWN": "orange",
                        }

                        return (
                            <TagField
                                color={colors[record.new_status_value as keyof typeof colors ]}
                                value={`${
                                    record?.new_status_value
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.old_status_value - b.old_status_value}
                    filters={tableQueryResult?.data?.data.map((
                        { old_status_value }) => ({ "text": old_status_value, "value": old_status_value})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { old_status_value: string; }) => record.old_status_value.startsWith(value)}
                    title="Old Status" 
                    dataIndex="old_status_value" 
                    render={(_: any, record: { old_status_value: any; }) => {
                        let colors = {
                            "to_be_deleted": "darkgreen",
                            "completed": "darkgreen",
                            "ready_to_be_processed": "blue",
                            "in_progress": "green",
                            "UNKNOWN": "orange",
                        }

                        return (
                            <TagField
                                color={colors[record.old_status_value as keyof typeof colors ]}
                                value={`${
                                    record?.old_status_value
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ITransactionsActivityLog>
                    sorter={ (a: any, b: any) => a.last_updated_timestamp - b.last_updated_timestamp}
                    filters={tableQueryResult?.data?.data.map((
                        { last_updated_timestamp }) => ({ "text": String(last_updated_timestamp), "value": String(last_updated_timestamp)})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { last_updated_timestamp: number; }) => String(record.last_updated_timestamp).startsWith(value)}
                    title="Last Updated Timestamp" 
                    dataIndex="last_updated_timestamp" 
                    render={(_: any, record: { last_updated_timestamp: any; }) => {
                        return (
                            <DateField value={dayjs.unix(Number(record?.last_updated_timestamp/1000) || 1)} format="LL LTS"></DateField>
                        );
                    }}
                /> 
                <Table.Column<ITransactionsActivityLog>
                    title="Actions"
                    dataIndex="arn"
                    render={(_: any, record: {
                        message_type: string;
                        region: string;
                        lock_value: string; }) => (
                        <Space>
                            <ShowButton
                                size="small"
                                recordItemId= {record.message_type + "_" + record.region + "_" + record.lock_value }
                            />
                        </Space>
                    )}
                />        
            </Table>
        </List>
    );
};

