import React, { useState } from 'react';
import { Card, Table, Tag, Space, Typography, Alert, Input, Button, Tooltip, Row, Col, Statistic, Divider, Timeline, Descriptions } from 'antd';
import { SearchOutlined, HistoryOutlined, ClockCircleOutlined, UserOutlined, EnvironmentOutlined, CodeOutlined, ApartmentOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Text, Title, Paragraph } = Typography;

interface ActivityLogsProps {
    record: any;
    showRawDataModal: (data: any, title: string) => void;
}

export const ActivityLogsTab: React.FC<ActivityLogsProps> = ({ record, showRawDataModal }) => {
    const [searchText, setSearchText] = useState('');
    const [filteredLogs, setFilteredLogs] = useState<any[]>([]);
    const [isFiltered, setIsFiltered] = useState(false);

    if (!record?.moralis_includes?.moralis_data?.activity_logs) {
        return (
            <Alert
                message="No Activity Logs"
                description="This work item does not contain any activity logs."
                type="info"
                showIcon
            />
        );
    }

    const activityLogs = record.moralis_includes.moralis_data.activity_logs || [];
    
    // Get the work item lock value to display in the header
    const workItemLockValue = record?.lock_value;
    
    // Function to format timestamp
    const formatTimestamp = (timestamp: string | number) => {
        if (!timestamp) return 'N/A';
        const date = typeof timestamp === 'string' && !timestamp.includes('T') 
            ? new Date(parseInt(timestamp)) 
            : new Date(timestamp);
        return date.toLocaleString();
    };

    // Function to get stage color
    const getStageColor = (stage: string) => {
        switch (stage?.toLowerCase()) {
            case 'pre_process':
                return 'blue';
            case 'process':
                return 'green';
            case 'post_process':
                return 'purple';
            default:
                return 'default';
        }
    };

    // Function to handle search
    const handleSearch = () => {
        if (!searchText) {
            setFilteredLogs([]);
            setIsFiltered(false);
            return;
        }

        const searchLower = searchText.toLowerCase();
        const filtered = activityLogs.filter((log: any) => {
            return Object.entries(log).some(([key, value]) => {
                if (typeof value === 'string') {
                    return value.toLowerCase().includes(searchLower);
                }
                if (value !== null && typeof value === 'object') {
                    return JSON.stringify(value).toLowerCase().includes(searchLower);
                }
                return String(value).toLowerCase().includes(searchLower);
            });
        });

        setFilteredLogs(filtered);
        setIsFiltered(true);
    };

    // Function to clear search
    const clearSearch = () => {
        setSearchText('');
        setFilteredLogs([]);
        setIsFiltered(false);
    };

    // Get logs to display (filtered or all)
    const logsToDisplay = isFiltered ? filteredLogs : activityLogs;

    // Group logs by stage for the timeline view
    const logsByStage = logsToDisplay.reduce((acc: any, log: any) => {
        const stage = log.stage || 'unknown';
        if (!acc[stage]) {
            acc[stage] = [];
        }
        acc[stage].push(log);
        return acc;
    }, {});

    // Sort stages in logical order
    const sortedStages = Object.keys(logsByStage).sort((a, b) => {
        const stageOrder: {[key: string]: number} = {
            'pre_process': 1,
            'process': 2,
            'post_process': 3,
            'unknown': 4
        };
        return (stageOrder[a] || 99) - (stageOrder[b] || 99);
    });

    return (
        <div>
            <Alert
                message="Activity Logs"
                description={
                    <div>
                        <p>Showing activity logs for the current work item only.</p>
                        {workItemLockValue && (
                            <p>
                                <strong>Lock Value:</strong>{' '}
                                <Text copyable>{workItemLockValue}</Text>
                            </p>
                        )}
                    </div>
                }
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
            />

            <Row gutter={16} style={{ marginBottom: 16 }}>
                <Col span={8}>
                    <Card>
                        <Statistic 
                            title="Total Logs" 
                            value={activityLogs.length} 
                            prefix={<HistoryOutlined />} 
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic 
                            title="Unique Stages" 
                            value={Object.keys(logsByStage).length} 
                            prefix={<ApartmentOutlined />} 
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic 
                            title="Last Updated" 
                            value={activityLogs.length > 0 ? formatTimestamp(activityLogs[0].last_updated_timestamp) : 'N/A'} 
                            prefix={<ClockCircleOutlined />}
                            valueStyle={{ fontSize: '14px' }}
                        />
                    </Card>
                </Col>
            </Row>

            <div style={{ marginBottom: 16 }}>
                <Input.Group compact>
                    <Input
                        style={{ width: 'calc(100% - 200px)' }}
                        placeholder="Search in activity logs..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onPressEnter={handleSearch}
                    />
                    <Button type="primary" onClick={handleSearch} icon={<SearchOutlined />}>
                        Search
                    </Button>
                    <Button onClick={clearSearch} disabled={!isFiltered}>
                        Clear
                    </Button>
                </Input.Group>
                {isFiltered && (
                    <Text type="secondary">
                        Showing {filteredLogs.length} of {activityLogs.length} logs
                    </Text>
                )}
            </div>

            <Divider orientation="left">Timeline View</Divider>
            
            <Timeline mode="left">
                {sortedStages.map(stage => (
                    <React.Fragment key={stage}>
                        {logsByStage[stage].map((log: any, index: number) => (
                            <Timeline.Item 
                                key={index}
                                color={getStageColor(stage)}
                                label={formatTimestamp(log.last_updated_timestamp)}
                            >
                                <Space direction="vertical" size={0} style={{ width: '100%' }}>
                                    <Space>
                                        <Tag color={getStageColor(stage)}>{stage}</Tag>
                                        <Text strong>{log.last_updated_action_reason || 'Action'}</Text>
                                    </Space>
                                    <Text type="secondary">
                                        <UserOutlined /> {log.last_updated_by}
                                    </Text>
                                    <Text type="secondary">
                                        <EnvironmentOutlined /> {log.environment}
                                    </Text>
                                    <Button 
                                        type="link" 
                                        size="small" 
                                        icon={<CodeOutlined />}
                                        onClick={() => showRawDataModal(log, `Activity Log: ${formatTimestamp(log.last_updated_timestamp)}`)}
                                    >
                                        View Details
                                    </Button>
                                </Space>
                            </Timeline.Item>
                        ))}
                    </React.Fragment>
                ))}
            </Timeline>

            <Divider orientation="left">Table View</Divider>
            
            <Table
                dataSource={logsToDisplay}
                rowKey={(record) => record.sk || record.last_updated_timestamp}
                columns={[
                    {
                        title: 'Timestamp',
                        dataIndex: 'last_updated_timestamp',
                        key: 'last_updated_timestamp',
                        render: (text) => formatTimestamp(text),
                        sorter: (a, b) => {
                            const aTime = a.last_updated_timestamp ? parseInt(a.last_updated_timestamp) : 0;
                            const bTime = b.last_updated_timestamp ? parseInt(b.last_updated_timestamp) : 0;
                            return aTime - bTime;
                        },
                        defaultSortOrder: 'descend'
                    },
                    {
                        title: 'Stage',
                        dataIndex: 'stage',
                        key: 'stage',
                        render: (text) => <Tag color={getStageColor(text)}>{text}</Tag>,
                        filters: sortedStages.map(stage => ({ text: stage, value: stage })),
                        onFilter: (value, record) => record.stage === value
                    },
                    {
                        title: 'Action',
                        dataIndex: 'last_updated_action_reason',
                        key: 'last_updated_action_reason'
                    },
                    {
                        title: 'Updated By',
                        dataIndex: 'last_updated_by',
                        key: 'last_updated_by',
                        ellipsis: true,
                        render: (text) => (
                            <Tooltip title={text}>
                                <Text>{text}</Text>
                            </Tooltip>
                        )
                    },
                    {
                        title: 'Environment',
                        dataIndex: 'environment',
                        key: 'environment',
                        filters: [
                            { text: 'sandbox', value: 'sandbox' },
                            { text: 'production', value: 'production' }
                        ],
                        onFilter: (value, record) => record.environment === value
                    },
                    {
                        title: 'Actions',
                        key: 'actions',
                        render: (_, record) => (
                            <Button 
                                type="link" 
                                icon={<CodeOutlined />}
                                onClick={() => showRawDataModal(record, `Activity Log: ${formatTimestamp(record.last_updated_timestamp)}`)}
                            >
                                Details
                            </Button>
                        )
                    }
                ]}
                pagination={{ pageSize: 10 }}
                expandable={{
                    expandedRowRender: (record) => (
                        <Descriptions bordered size="small" column={2}>
                            <Descriptions.Item label="Include ID">{record.include_id}</Descriptions.Item>
                            <Descriptions.Item label="Message Type">{record.message_type}</Descriptions.Item>
                            <Descriptions.Item label="Block Number">{record.block_number}</Descriptions.Item>
                            <Descriptions.Item label="Block Timestamp">{record.block_timestamp}</Descriptions.Item>
                            <Descriptions.Item label="Address" span={2}>
                                <Text copyable>{record.address}</Text>
                            </Descriptions.Item>
                            <Descriptions.Item label="Hash" span={2}>
                                <Text copyable>{record.hash}</Text>
                            </Descriptions.Item>
                            <Descriptions.Item label="Chain">{record.chain}</Descriptions.Item>
                            <Descriptions.Item label="AWS Step Function">
                                {record.aws_step_functions_execution_id && (
                                    <Text copyable={{ text: record.aws_step_functions_execution_id }}>
                                        {record.aws_step_functions_execution_id.split(':').pop()}
                                    </Text>
                                )}
                            </Descriptions.Item>
                        </Descriptions>
                    )
                }}
            />
        </div>
    );
}; 