import React, { useState } from 'react';
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Alert, Card, Col, Collapse, Descriptions, Divider, Row, Space, Statistic, Tabs, Tag, Typography, Empty, Input, Button, Modal, message, Tooltip, Spin, Popconfirm, Timeline, List } from 'antd';
import { Show } from '@refinedev/antd';
import { IWorkItem } from 'interfaces';
import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined, InfoCircleOutlined, SyncOutlined, CopyOutlined, CodeOutlined, ReloadOutlined, LinkOutlined, HistoryOutlined, PlayCircleOutlined, PauseCircleOutlined, StopOutlined, SendOutlined, FileSearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { TaskResultTab } from './components/task-result';
import { ActivityLogsTab } from './components/activity-logs';
import { WorkItemInfo } from './components/work-item-info';

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;
const { TabPane } = Tabs;

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import { Link } from 'react-router-dom';

export const WorkItemShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IWorkItem>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const [activeTab, setActiveTab] = useState<string>("overview");
    const [jsonSearchText, setJsonSearchText] = useState<string>("");
    const [rawDataModalVisible, setRawDataModalVisible] = useState<boolean>(false);
    const [currentRawData, setCurrentRawData] = useState<any>(null);
    const [rawDataModalTitle, setRawDataModalTitle] = useState<string>("");
    const [resendingItem, setResendingItem] = useState<string | null>(null);
    const [resendError, setResendError] = useState<string | null>(null);
    const [resendSuccess, setResendSuccess] = useState<boolean>(false);
    const [resendMessageId, setResendMessageId] = useState<string | null>(null);
    const [lastResendedItemId, setLastResendedItemId] = useState<string | null>(null);
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    const [executionActionModalVisible, setExecutionActionModalVisible] = useState(false);
    const [selectedExecution, setSelectedExecution] = useState<any>(null);
    const [actionType, setActionType] = useState<'cancel' | 'callback'>('cancel');
    const [callbackInput, setCallbackInput] = useState('');
    const [executionActionLoading, setExecutionActionLoading] = useState(false);

    // Color mappings for various fields
    const colors = {
        region: {
            "us-west-2": "green",
            "us-east-1": "lightgreen",
            "eu-central-1": "blue",
            "eu-west-1": "lightblue"
        },
        status: {
            "to_be_deleted": "red",
            "ready_to_be_processed": "blue",
            "in_progress": "green",
            "completed": "purple",
            "failed": "volcano"
        },
        environment: {
            "production": "red",
            "sandbox": "green",
            "development": "blue"
        }
    };

    // Format timestamp to readable date
    const formatTimestamp = (timestamp: number) => {
        if (!timestamp) return "N/A";
        return new Date(timestamp).toLocaleString();
    };

    // Function to show raw data modal
    const showRawDataModal = (data: any, title: string) => {
        setCurrentRawData(data);
        setRawDataModalTitle(title);
        setRawDataModalVisible(true);
    };

    // Function to resend an include item for processing
    const resendIncludeItem = async (item: any) => {
        try {
            const itemId = item.lock_value;
            setResendingItem(itemId);
            setResendError(null);
            setResendSuccess(false);
            setResendMessageId(null);
            
            // Determine the environment from the record
            const environment = record?.env || 'sandbox';
            const region = record?.region || 'us-west-2';
            const continent = record?.continent || 'us';
            
            // Base message with common required fields
            let messagePayload: any = {
                org_id: record?.org_id,
                tenant_id: record?.tenant_id,
                user_id: record?.user_id,
                continent: continent,
                region: region,
                type: "include",
                create_work_item: true,
                is_automatic_process: false,
                force_update: false
            };
            
            // Determine queue name and add type-specific fields
            let queueName = "";
            
            if (item.include_config?.include_type === "WALLET") {
                // For wallet type
                if (record?.is_blue) {
                    queueName = "-moralis-mgmt-request-moralis-wallet-stats-queue-blue";
                }
                else{
                    queueName = "-moralis-mgmt-request-moralis-wallet-stats-queue";
                }
                messagePayload.addresses = [item.include_config.include_address];
                messagePayload.chains = [item.include_config.include_chain];
                
                // Add include_config if available
                if (item.include_config) {
                    messagePayload.include_config = item.include_config;
                    messagePayload.message_type = "include";
                }
            } else if (item.include_config?.include_type === "TRANSACTION") {
                // For transaction type
                if (record?.is_blue) {
                    queueName = "-moralis-mgmt-request-moralis-transaction-queue-blue";
                }
                else{
                    queueName = "-moralis-mgmt-request-moralis-transaction-queue";
                }    
                            
                // Required fields for transaction type
                messagePayload.chains = [item.include_config.include_chain];
                messagePayload.addresses = [item.include_config.include_address];
                
                // Add hash from request or from record
                if (item.include_config.request?.hash && Array.isArray(item.include_config.request.hash)) {
                    messagePayload.hash = item.include_config.request.hash;
                } else if (record?.hash) {
                    messagePayload.hash = [record.hash];
                } else {
                    // Generate a hash from the address if needed
                    const addressHash = item.include_config.include_address || record?.address;
                    if (addressHash) {
                        messagePayload.hash = [addressHash];
                    } else {
                        throw new Error("No transaction hash available for this item");
                    }
                }
                
                // Add include_config if available
                if (item.include_config) {
                    messagePayload.include_config = item.include_config;
                    messagePayload.message_type = "include";
                }
                
                // Add additional required fields
                messagePayload.include_internal_transactions = true;
            } else {
                throw new Error(`Unknown include type: ${item.include_config?.include_type}`);
            }
            
            // Add tracer_config if available
            if (record?.tracer_config) {
                messagePayload.tracer_config = record.tracer_config;
            }
            
            // Prepare the request payload
            const payload = {
                queue_name: queueName,
                message: messagePayload,
                region: region,
                environment: environment
            };
            
            console.log("Sending payload:", JSON.stringify(payload, null, 2));
            
            // Get the domain from the current URL
            const domain = window.location.hostname.includes('localhost') 
                ? 'sandbox.authe.io' 
                : window.location.hostname.split('.').slice(-2).join('.');
            
            // Make the API call
            const url = `https://${continent.toLowerCase()}.api.${domain}/internal/tools/moralis-mgmt-debug-tool/blue/ANY/US/v0/send-message-to-moralis-queue`;

            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "admin-portal",
                "Source-Region": "us-west-2",
                "Destination-Region": "us-west-2",
            };

            const response = await axios.post(url, payload, {
                headers: requestHeaders
            });

            if (response?.data?.statusCode === 200) {
                setResendSuccess(true);
                
                // Store the message ID if available
                if (response?.data?.result?.message_id) {
                    console.log("Setting message ID:", response.data.result.message_id);
                    setResendMessageId(response.data.result.message_id);
                    setLastResendedItemId(itemId);
                }
                
                setResendSuccess(true);
                message.success(`Successfully resent item ${itemId} for processing`);
            } else {
                throw new Error(`Failed to resend: ${response?.data?.message}`);
            }
        } catch (error) {
            console.error("Error resending item:", error);
            setResendError(error instanceof Error ? error.message : String(error));
            message.error(`Failed to resend item: ${error instanceof Error ? error.message : String(error)}`);
        } finally {
            setResendingItem(null);
        }
    };

    // Function to generate log group links
    const getLogGroupLinks = (messageId: string, itemType: string, env: string, region: string) => {
        // Determine the region alias (e.g., pdx for us-west-2)
        const regionAliasMap: Record<string, string> = {
            'us-west-2': 'pdx',
            'us-east-1': 'iad',
            'eu-central-1': 'fra',
            // Add more mappings as needed
        };
        
        const regionAlias = regionAliasMap[region] || region;
        const envPrefix = env === 'production' ? 'prd' : env === 'sandbox' ? 'sandbox' : env;
        
        // Determine log group names based on item type
        const logGroups = [];
        
        if (itemType === 'WALLET') {
            logGroups.push({
                name: 'Wallet Processing',
                url: `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:log-groups/log-group/$252Faws$252Flambda$252F${envPrefix}-${regionAlias}-moralis-mgmt-get-moralis-wallet-stats`
            });
        } else if (itemType === 'TRANSACTION') {
            logGroups.push({
                name: 'Transaction Processing',
                url: `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:log-groups/log-group/$252Faws$252Flambda$252F${envPrefix}-${regionAlias}-moralis-mgmt-retrieve-and-preprocess-transactions`
            });
        }
        
        // Add general log groups
        logGroups.push({
            name: 'SQS Processing',
            url: `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:log-groups/log-group/$252Faws$252Flambda$252F${envPrefix}-${regionAlias}-moralis-mgmt-process-sqs-messages`
        });
        
        return logGroups;
    };

    // Get status for includes summary
    const getIncludesSummary = () => {
        if (!record?.moralis_includes?.summary) return null;
        
        const summary = record.moralis_includes.summary;
        
        return (
            <Card title="Includes Summary" bordered={false}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Statistic 
                            title="Total Includes" 
                            value={summary.total_includes} 
                            valueStyle={{ color: '#1890ff' }}
                            prefix={<InfoCircleOutlined />}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic 
                            title="Completed" 
                            value={summary.completed_includes} 
                            valueStyle={{ color: '#52c41a' }}
                            prefix={<CheckCircleOutlined />}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic 
                            title="Processing" 
                            value={summary.processing_includes} 
                            valueStyle={{ color: '#faad14' }}
                            prefix={<SyncOutlined spin />}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic 
                            title="Failed" 
                            value={summary.failed_includes} 
                            valueStyle={{ color: '#f5222d' }}
                            prefix={<ExclamationCircleOutlined />}
                        />
                    </Col>
                </Row>
                
                <Divider orientation="left">Status Breakdown</Divider>
                
                <Row gutter={16}>
                    {Object.entries(summary.processing_status).map(([status, count]) => (
                        <Col span={6} key={status}>
                            <Statistic 
                                title={status.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} 
                                value={count as number} 
                                valueStyle={{ color: 
                                    status === 'completed' ? '#52c41a' : 
                                    status === 'failed' ? '#f5222d' : 
                                    status === 'processing' ? '#faad14' : '#1890ff' 
                                }}
                            />
                        </Col>
                    ))}
                </Row>
                
                <Divider orientation="left">Type Breakdown</Divider>
                
                {Object.entries(summary.results_by_type).map(([type, data]:any) => (
                    <div key={type} style={{ marginBottom: '16px' }}>
                        <Title level={5}>{type}</Title>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Statistic title="Total" value={data.total} />
                            </Col>
                            <Col span={6}>
                                <Statistic 
                                    title="Completed" 
                                    value={data.completed} 
                                    valueStyle={{ color: '#52c41a' }}
                                />
                            </Col>
                            <Col span={6}>
                                <Statistic 
                                    title="Processing" 
                                    value={data.processing} 
                                    valueStyle={{ color: '#faad14' }}
                                />
                            </Col>
                            <Col span={6}>
                                <Statistic 
                                    title="Failed" 
                                    value={data.failed} 
                                    valueStyle={{ color: '#f5222d' }}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </Card>
        );
    };

    // Get status for includes items by status
    const getIncludesByStatus = () => {
        if (!record?.moralis_includes?.moralis_data) return null;
        
        // Extract all include items
        const allIncludes: any[] = [];
        Object.entries(record.moralis_includes.moralis_data).forEach(([includeId, data]: [string, any]) => {
            if (data.include_items && Array.isArray(data.include_items)) {
                data.include_items.forEach((item: any) => {
                    allIncludes.push({
                        ...item,
                        includeId,
                        // Determine status based on available data
                        // If item has result attribute or s3_result_location, it's completed regardless of status
                        status: (item.result || item.s3_result_location) ? "completed" : (
                            item.status || 
                            (item.include_config?.processing_status) || 
                            (item.include_config?.waiting_for_total_items_to_be_included ? "processing" : "unknown")
                        )
                    });
                });
            }
        });
        
        // Group by status
        const processingItems = allIncludes.filter(item => 
            item.status === "processing" || 
            item.status === "ready_to_be_processed"
        );
        
        const failedItems = allIncludes.filter(item => 
            item.status === "failed" || 
            item.status === "error"
        );
        
        const successfulItems = allIncludes.filter(item => 
            item.status === "completed" || 
            item.status === "success" ||
            item.result !== undefined ||  // Consider items with result as successful
            item.s3_result_location !== undefined  // Consider items with s3_result_location as successful
        );
        
        // Create a copyable text component
        const CopyableText = ({ value }: { value: string }) => (
            <Space>
                <Text>{value}</Text>
                <Typography.Text
                    copyable={{
                        text: value,
                        icon: <CopyOutlined style={{ color: '#1890ff' }} />,
                        tooltips: ['Copy', 'Copied!'],
                    }}
                />
            </Space>
        );
        
        // Create a component for the panel header with better layout
        const ItemHeader = ({ item }: { item: any }) => {
            const includeId = item.includeId || "";
            const includeType = item.include_config?.include_type || "";
            const includeAddress = item.include_config?.include_address || "";
            
            // Truncate long values for display
            const truncateText = (text: string, maxLength: number = 12) => {
                if (!text) return "";
                return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
            };
            
            // Show result indicators if available
            const hasResult = !!item.result;
            const hasS3Result = !!item.s3_result_location;
            
            return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <div>
                        <Tag color="blue">{truncateText(includeId, 16)}</Tag>
                        {hasResult && <Tag color="green">Has Result</Tag>}
                        {hasS3Result && <Tag color="cyan">S3 Result</Tag>}
                        <Tooltip title={includeId}>
                            <CopyOutlined 
                                style={{ marginLeft: 4, color: '#1890ff', cursor: 'pointer' }} 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(includeId);
                                    message.success('ID copied to clipboard');
                                }}
                            />
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <Tag color="purple" style={{ margin: 0 }}>{includeType}</Tag>
                        <Tooltip title={includeAddress}>
                            <Text ellipsis style={{ maxWidth: '150px' }}>{includeAddress}</Text>
                        </Tooltip>
                    </div>
                </div>
            );
        };
        
        // Create a component to display result data if available
        const ResultDisplay = ({ result, s3ResultLocation }: { result: any, s3ResultLocation?: string }) => {
            if (!result && !s3ResultLocation) return null;
            
            return (
                <>
                    <Divider orientation="left">Result Data</Divider>
                    <Card size="small">
                        {result && (
                            <AceEditor
                                mode="json"
                                theme="monokai"
                                name="result-editor"
                                value={JSON.stringify(result, null, 2)}
                                readOnly={true}
                                width="100%"
                                height="200px"
                                setOptions={{ useWorker: false }}
                            />
                        )}
                        
                        {s3ResultLocation && (
                            <div style={{ marginTop: result ? '16px' : 0 }}>
                                <Typography.Title level={5}>S3 Result Location</Typography.Title>
                                <Input.TextArea 
                                    value={s3ResultLocation} 
                                    readOnly 
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                    style={{ marginBottom: '8px' }}
                                />
                                <Button 
                                    type="primary" 
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                        navigator.clipboard.writeText(s3ResultLocation);
                                        message.success('S3 path copied to clipboard');
                                    }}
                                >
                                    Copy S3 Path
                                </Button>
                            </div>
                        )}
                    </Card>
                </>
            );
        };
        
        // Create a component for the panel content
        const PanelContent = ({ item, itemType }: { item: any, itemType: 'processing' | 'failed' | 'successful' }) => {
            const isLastResendedItem = item.lock_value === lastResendedItemId;
            
            return (
                <>
                    {resendSuccess && isLastResendedItem && resendMessageId && (
                        <Alert
                            message="Item Successfully Resent"
                            description={
                                <div>
                                    <p><strong>Message ID:</strong> {resendMessageId}</p>
                                    <p><strong>Log Groups:</strong></p>
                                    <ul style={{ paddingLeft: '20px' }}>
                                        {getLogGroupLinks(
                                            resendMessageId, 
                                            item.include_config?.include_type, 
                                            record?.env || 'sandbox', 
                                            record?.region || 'us-west-2'
                                        ).map((logGroup, index) => (
                                            <li key={index}>
                                                <a 
                                                    href={logGroup.url} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer"
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <LinkOutlined style={{ marginRight: 8 }} />
                                                    {logGroup.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                    <p style={{ marginTop: '8px' }}>
                                        <Typography.Text type="secondary">
                                            Tip: Search for the Message ID in the logs to track processing
                                        </Typography.Text>
                                    </p>
                                </div>
                            }
                            type="success"
                            showIcon
                            style={{ marginBottom: '16px' }}
                        />
                    )}
                    
                    {resendError && isLastResendedItem && (
                        <Alert
                            message="Error Resending Item"
                            description={resendError}
                            type="error"
                            showIcon
                            style={{ marginBottom: '16px' }}
                        />
                    )}
                    
                    <Descriptions bordered size="small" column={1}>
                        <Descriptions.Item label="Include ID">
                            <CopyableText value={item.includeId} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Type">
                            <CopyableText value={item.include_config?.include_type || ""} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Address">
                            <CopyableText value={item.include_config?.include_address || ""} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Chain">
                            <CopyableText value={item.include_config?.include_chain || ""} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Created By">
                            <CopyableText value={item.created_by || ""} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Created">
                            <CopyableText value={formatTimestamp(parseInt(item.created_timestamp)) || ""} />
                        </Descriptions.Item>
                        <Descriptions.Item label="TTL">
                            <CopyableText value={formatTimestamp(parseInt(item.ttl)) || ""} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Lock Key">
                            <CopyableText value={item.lock_key || ""} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Lock Value">
                            <CopyableText value={item.lock_value || ""} />
                        </Descriptions.Item>
                    </Descriptions>
                    
                    {/* Display result data if available */}
                    <ResultDisplay 
                        result={item.result} 
                        s3ResultLocation={item.s3_result_location}
                    />
                    
                    <Divider orientation="left">Include Config</Divider>
                    <AceEditor
                        mode="json"
                        theme="monokai"
                        name={`editor-config-${item.includeId}`}
                        value={JSON.stringify(item.include_config || {}, null, 2)}
                        readOnly={true}
                        width="100%"
                        height="200px"
                        setOptions={{ useWorker: false }}
                    />
                </>
            );
        };
        
        return (
            <div>
                <Row gutter={16} style={{ marginBottom: '16px' }}>
                    <Col xs={24} md={8}>
                        <Card 
                            title={
                                <span>
                                    <SyncOutlined spin style={{ marginRight: 8 }} />
                                    Processing Items ({processingItems.length})
                                </span>
                            } 
                            bordered={false}
                            style={{ marginBottom: '16px' }}
                        >
                            {processingItems.length > 0 ? (
                                <Collapse>
                                    {processingItems.map((item, index) => (
                                        <Panel 
                                            header={<ItemHeader item={item} />}
                                            key={index}
                                            extra={<ItemActions item={item} itemType="processing" />}
                                        >
                                            <PanelContent item={item} itemType="processing" />
                                        </Panel>
                                    ))}
                                </Collapse>
                            ) : (
                                <Empty description="No processing items" />
                            )}
                        </Card>
                    </Col>
                    
                    <Col xs={24} md={8}>
                        <Card 
                            title={
                                <span>
                                    <ExclamationCircleOutlined style={{ marginRight: 8, color: '#f5222d' }} />
                                    Failed Items ({failedItems.length})
                                </span>
                            } 
                            bordered={false}
                            style={{ marginBottom: '16px' }}
                        >
                            {failedItems.length > 0 ? (
                                <Collapse>
                                    {failedItems.map((item, index) => (
                                        <Panel 
                                            header={<ItemHeader item={item} />}
                                            key={index}
                                            extra={<ItemActions item={item} itemType="failed" />}
                                        >
                                            <PanelContent item={item} itemType="failed" />
                                        </Panel>
                                    ))}
                                </Collapse>
                            ) : (
                                <Empty description="No failed items" />
                            )}
                        </Card>
                    </Col>
                    
                    <Col xs={24} md={8}>
                        <Card 
                            title={
                                <span>
                                    <CheckCircleOutlined style={{ marginRight: 8, color: '#52c41a' }} />
                                    Successful Items ({successfulItems.length})
                                </span>
                            } 
                            bordered={false}
                        >
                            {successfulItems.length > 0 ? (
                                <Collapse>
                                    {successfulItems.map((item, index) => (
                                        <Panel 
                                            header={<ItemHeader item={item} />}
                                            key={index}
                                            extra={<ItemActions item={item} itemType="successful" />}
                                        >
                                            <PanelContent item={item} itemType="successful" />
                                        </Panel>
                                    ))}
                                </Collapse>
                            ) : (
                                <Empty description="No successful items" />
                            )}
                        </Card>
                    </Col>
                </Row>
                
                {/* Raw Data Modal */}
                <Modal
                    title={rawDataModalTitle}
                    visible={rawDataModalVisible}
                    onCancel={() => setRawDataModalVisible(false)}
                    width={800}
                    footer={[
                        <Button 
                            key="copy" 
                            icon={<CopyOutlined />}
                            onClick={() => {
                                navigator.clipboard.writeText(JSON.stringify(currentRawData, null, 2));
                                message.success('Raw data copied to clipboard');
                            }}
                        >
                            Copy to Clipboard
                        </Button>,
                        <Button 
                            key="close" 
                            type="primary" 
                            onClick={() => setRawDataModalVisible(false)}
                        >
                            Close
                        </Button>
                    ]}
                >
                    <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                        <pre style={{ 
                            backgroundColor: '#282c34', 
                            color: '#abb2bf', 
                            padding: '16px', 
                            borderRadius: '4px',
                            overflow: 'auto'
                        }}>
                            {JSON.stringify(currentRawData, null, 2)}
                        </pre>
                    </div>
                </Modal>
            </div>
        );
    };
    
    // Display original task result
    const getOriginalTaskResult = () => {
        if (!record?.moralis_includes?.moralis_data) return null;
        
        return (
            <div>
                <Title level={4}>Original Task Results</Title>
                <Collapse>
                    {Object.entries(record.moralis_includes.moralis_data).map(([includeId, data]: [string, any]) => {
                        if (!data.task_result?.origional_task_result) return null;
                        
                        return (
                            <Panel 
                                header={
                                    <Space>
                                        <Tag color="blue">{includeId}</Tag>
                                        <Text>Original Task Result</Text>
                                    </Space>
                                } 
                                key={includeId}
                            >
                                <AceEditor
                                    mode="json"
                                    theme="monokai"
                                    name={`editor-original-${includeId}`}
                                    value={JSON.stringify(data.task_result.origional_task_result, null, 2)}
                                    readOnly={true}
                                    width="100%"
                                    height="400px"
                                    setOptions={{ 
                                        useWorker: false,
                                        showPrintMargin: false,
                                        highlightActiveLine: true,
                                        highlightGutterLine: true,
                                        showLineNumbers: true,
                                        tabSize: 2,
                                        fontSize: 14
                                    }}
                                />
                            </Panel>
                        );
                    })}
                </Collapse>
            </div>
        );
    };

    // Create a consistent action buttons component
    const ItemActions = ({ item, itemType }: { item: any, itemType: 'processing' | 'failed' | 'successful' }) => {
        const isLastResendedItem = item.includeId === lastResendedItemId;
        
        return (
            <Space size="small" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Popconfirm
                    title="Resend for processing"
                    description="Are you sure you want to resend this item for processing?"
                    onConfirm={(e) => {
                        e?.stopPropagation();
                        resendIncludeItem(item);
                    }}
                    onCancel={(e) => e?.stopPropagation()}
                    okText="Yes"
                    cancelText="No"
                >
                    <Tooltip title="Resend for processing">
                        <Button 
                            type="primary"
                            icon={<ReloadOutlined />}
                            size="small"
                            danger={itemType === 'failed'}
                            loading={resendingItem === item.includeId}
                            onClick={(e) => e.stopPropagation()}
                            style={{ minWidth: '32px', padding: '0 8px' }}
                        />
                    </Tooltip>
                </Popconfirm>
                <Button 
                    type="link" 
                    icon={<CodeOutlined />}
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        showRawDataModal(item, `${itemType.charAt(0).toUpperCase() + itemType.slice(1)} Item: ${item.includeId}`);
                    }}
                    style={{ padding: '0 8px' }}
                >
                    Raw
                </Button>
                {isLastResendedItem && resendSuccess && (
                    <Tag color="green">Last Resent</Tag>
                )}
            </Space>
        );
    };

    // Add helper functions for step function status
    const getStepFunctionStatusColor = (status?: string) => {
        if (!status) return '';
        switch (status.toUpperCase()) {
            case 'RUNNING':
                return 'processing';
            case 'SUCCEEDED':
                return 'success';
            case 'FAILED':
                return 'error';
            case 'TIMED_OUT':
                return 'warning';
            case 'ABORTED':
                return 'default';
            default:
                return '';
        }
    };

    const getStepFunctionStatusIcon = (status?: string) => {
        if (!status) return null;
        switch (status.toUpperCase()) {
            case 'RUNNING':
                return <SyncOutlined spin />;
            case 'SUCCEEDED':
                return <CheckCircleOutlined />;
            case 'FAILED':
                return <ExclamationCircleOutlined />;
            case 'TIMED_OUT':
                return <ClockCircleOutlined />;
            case 'ABORTED':
                return <StopOutlined />;
            default:
                return <InfoCircleOutlined />;
        }
    };

    // Helper function to format task information
    const formatTaskInfo = (task: any) => {
        if (!task) return null;
        
        if (typeof task === 'object') {
            return (
                <Space direction="vertical" size="small">
                    <Text strong>{task.name || 'Unknown Task'}</Text>
                    {task.resource && <Text type="secondary">Resource: {task.resource}</Text>}
                    {task.resource_type && <Text type="secondary">Type: {task.resource_type}</Text>}
                    {task.timestamp && <Text type="secondary">Time: {formatTimestamp(new Date(task.timestamp).getTime())}</Text>}
                    {task.status && <Tag color={getStepFunctionStatusColor(task.status)}>{task.status}</Tag>}
                </Space>
            );
        }
        
        return String(task);
    };

    // Update the getStepFunctionDetails function to handle the new data structure
    const getStepFunctionDetails = () => {
        if (!record?.step_function_status) {
            return <Empty description="No step function information available" />;
        }

        const sfn = record.step_function_status;
        
        return (
            <div>
                {/* Overview section */}
                <Card title="Step Function Execution" style={{ marginBottom: 16 }}>
                    <Descriptions bordered column={2} size="small">
                        <Descriptions.Item label="Execution ARN" span={2}>
                            <Text copyable={{ text: sfn.execution_arn }}>
                                {sfn.execution_arn?.split(':').pop()}
                            </Text>
                            <Button 
                                type="link" 
                                icon={<LinkOutlined />}
                                href={`https://${record.region || 'us-west-2'}.console.aws.amazon.com/states/home?region=${record.region || 'us-west-2'}#/executions/details/${sfn.execution_arn}`}
                                target="_blank"
                            >
                                View in AWS Console
                            </Button>
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <Tag icon={getStepFunctionStatusIcon(sfn.status)} color={getStepFunctionStatusColor(sfn.status)}>
                                {sfn.status}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Current State">
                            {sfn.current_state?.name || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Start Date">
                            {sfn.start_date ? formatTimestamp(new Date(sfn.start_date).getTime()) : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Stop Date">
                            {sfn.stop_date ? formatTimestamp(new Date(sfn.stop_date).getTime()) : 'N/A'}
                        </Descriptions.Item>
                        
                        {sfn.waiting_in && (
                            <Descriptions.Item label="Waiting In" span={2}>
                                <Space direction="vertical">
                                    <Text>{sfn.waiting_in.state || sfn.waiting_in.name}</Text>
                                    {sfn.waiting_in.type && (
                                        <Tag color={sfn.waiting_in.type === 'Task' ? 'blue' : 
                                                sfn.waiting_in.type === 'MapRun' ? 'purple' : 'default'}>
                                            {sfn.waiting_in.type}
                                        </Tag>
                                    )}
                                    {sfn.waiting_in.timestamp && (
                                        <Text type="secondary">Since: {formatTimestamp(new Date(sfn.waiting_in.timestamp).getTime())}</Text>
                                    )}
                                </Space>
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Card>
                
                {/* Map runs section */}
                {sfn.current_state?.active_map_runs && sfn.current_state.active_map_runs.length > 0 && (
                    <Card title="Map Runs" style={{ marginBottom: 16 }}>
                        <Collapse>
                            {sfn.current_state.active_map_runs.map((mapRun: any, index: number) => (
                                <Panel 
                                    header={
                                        <Space>
                                            <Text strong>Map Run {index + 1}</Text>
                                            <Tag color={getStepFunctionStatusColor(mapRun.status)}>
                                                {mapRun.status}
                                            </Tag>
                                            <Space>
                                                <Tag color="blue">{mapRun.item_counts?.total || 0} total</Tag>
                                                <Tag color="success">{mapRun.item_counts?.succeeded || 0} succeeded</Tag>
                                                <Tag color="processing">{mapRun.item_counts?.running || 0} running</Tag>
                                                <Tag color="error">{mapRun.item_counts?.failed || 0} failed</Tag>
                                            </Space>
                                        </Space>
                                    }
                                    key={index}
                                >
                                    <Descriptions bordered column={2} size="small">
                                        <Descriptions.Item label="Map Run ARN" span={2}>
                                            <Text copyable={{ text: mapRun.map_run_arn }}>
                                                {mapRun.map_run_arn?.split(':').pop()}
                                            </Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Start Date">
                                            {mapRun.start_date ? formatTimestamp(new Date(mapRun.start_date).getTime()) : 'N/A'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Stop Date">
                                            {mapRun.stop_date ? formatTimestamp(new Date(mapRun.stop_date).getTime()) : 'N/A'}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Parent State">
                                            {mapRun.parent_state || 'N/A'}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    
                                    {/* Tasks summary section */}
                                    {mapRun.tasks_summary && Object.keys(mapRun.tasks_summary).length > 0 && (
                                        <>
                                            <Divider orientation="left">Tasks Summary</Divider>
                                            <List
                                                size="small"
                                                dataSource={Object.entries(mapRun.tasks_summary)}
                                                renderItem={([taskName, taskInfo]: [string, any]) => (
                                                    <List.Item>
                                                        <Space direction="vertical" style={{ width: '100%' }}>
                                                            <Space>
                                                                <Text strong>{taskName}</Text>
                                                                <Tag color="blue">{taskInfo.type || 'Task'}</Tag>
                                                                <Tag color="green">{taskInfo.count} executions</Tag>
                                                            </Space>
                                                            {taskInfo.executions && taskInfo.executions.length > 0 && (
                                                                <Collapse ghost>
                                                                    <Panel header="View Executions" key="executions">
                                                                        <List
                                                                            size="small"
                                                                            dataSource={taskInfo.executions}
                                                                            renderItem={(execution: string) => (
                                                                                <List.Item>
                                                                                    <Text copyable>{execution}</Text>
                                                                                </List.Item>
                                                                            )}
                                                                        />
                                                                    </Panel>
                                                                </Collapse>
                                                            )}
                                                        </Space>
                                                    </List.Item>
                                                )}
                                            />
                                        </>
                                    )}
                                    
                                    {/* Child executions section */}
                                    {mapRun.child_executions && mapRun.child_executions.length > 0 && (
                                        <div style={{ marginTop: 16 }}>
                                            <Divider orientation="left">Child Executions</Divider>
                                            <Collapse>
                                                {mapRun.child_executions.map((child: any, childIndex: number) => (
                                                    <Panel 
                                                        header={
                                                            <Space>
                                                                <Text strong>Child Execution {childIndex + 1}</Text>
                                                                <Tag color={getStepFunctionStatusColor(child.status)}>
                                                                    {child.status}
                                                                </Tag>
                                                                {formatTimestamp(new Date(child.start_date).getTime())}
                                                            </Space>
                                                        }
                                                        key={childIndex}
                                                    >
                                                        <Descriptions bordered column={1} size="small">
                                                            <Descriptions.Item label="Execution ARN">{child.execution_arn}</Descriptions.Item>
                                                            <Descriptions.Item label="Status">{child.status}</Descriptions.Item>
                                                            <Descriptions.Item label="Start Date">{formatTimestamp(new Date(child.start_date).getTime())}</Descriptions.Item>
                                                            {child.stop_date && (
                                                                <Descriptions.Item label="Stop Date">{formatTimestamp(new Date(child.stop_date).getTime())}</Descriptions.Item>
                                                            )}
                                                            
                                                            {/* Handle current tasks */}
                                                            {child.current_tasks && child.current_tasks.length > 0 && (
                                                                <Descriptions.Item label="Current Tasks">
                                                                    <List
                                                                        size="small"
                                                                        dataSource={child.current_tasks}
                                                                        renderItem={(task: any) => (
                                                                            <List.Item>
                                                                                <Space>
                                                                                    <Tag color={task.type === 'Task' ? 'blue' : 'purple'}>{task.type}</Tag>
                                                                                    <Text>{task.name}</Text>
                                                                                    <Text type="secondary">{formatTimestamp(new Date(task.timestamp).getTime())}</Text>
                                                                                </Space>
                                                                            </List.Item>
                                                                        )}
                                                                    />
                                                                </Descriptions.Item>
                                                            )}
                                                            
                                                            {/* Handle waiting tasks */}
                                                            {child.waiting_tasks && child.waiting_tasks.length > 0 && (
                                                                <Descriptions.Item label="Waiting Tasks">
                                                                    <List
                                                                        size="small"
                                                                        dataSource={child.waiting_tasks}
                                                                        renderItem={(task: any) => (
                                                                            <List.Item>
                                                                                <Space direction="vertical" size={0}>
                                                                                    <Space>
                                                                                        <Text strong>{task.name}</Text>
                                                                                        <Tag color={getStepFunctionStatusColor(task.status)}>{task.status}</Tag>
                                                                                    </Space>
                                                                                    <Text type="secondary">Resource: {task.resource}</Text>
                                                                                    <Text type="secondary">Type: {task.resource_type}</Text>
                                                                                    <Text type="secondary">Time: {formatTimestamp(new Date(task.timestamp).getTime())}</Text>
                                                                                </Space>
                                                                            </List.Item>
                                                                        )}
                                                                    />
                                                                </Descriptions.Item>
                                                            )}
                                                            
                                                            {/* Handle waiting_in */}
                                                            {child.waiting_in && (
                                                                <Descriptions.Item label="Waiting In">
                                                                    {formatTaskInfo(child.waiting_in)}
                                                                </Descriptions.Item>
                                                            )}
                                                            
                                                            {/* Action Buttons */}
                                                            <Descriptions.Item label="Actions">
                                                                <Space>
                                                                    {child.status === 'RUNNING' && (
                                                                        <Button 
                                                                            type="primary" 
                                                                            danger
                                                                            icon={<StopOutlined />}
                                                                            onClick={() => showExecutionActionModal(child, 'cancel')}
                                                                        >
                                                                            Cancel Execution
                                                                        </Button>
                                                                    )}
                                                                    
                                                                    {child.waiting_in && (
                                                                        <Button 
                                                                            type="primary"
                                                                            icon={<SendOutlined />}
                                                                            onClick={() => showExecutionActionModal(child, 'callback')}
                                                                        >
                                                                            Send Callback
                                                                        </Button>
                                                                    )}
                                                                </Space>
                                                            </Descriptions.Item>
                                                            
                                                            {/* Input/Output */}
                                                            <Descriptions.Item label="Input">
                                                                <Button 
                                                                    type="link" 
                                                                    onClick={() => {
                                                                        // Fix for modal not showing - ensure we're using the correct function
                                                                        showRawDataModal(child.input, 'Child Execution Input');
                                                                    }}
                                                                >
                                                                    View Input
                                                                </Button>
                                                            </Descriptions.Item>
                                                            {child.output && (
                                                                <Descriptions.Item label="Output">
                                                                    <Button 
                                                                        type="link" 
                                                                        onClick={() => showRawDataModal(child.output, 'Child Execution Output')}
                                                                    >
                                                                        View Output
                                                                    </Button>
                                                                </Descriptions.Item>
                                                            )}
                                                        </Descriptions>
                                                    </Panel>
                                                ))}
                                            </Collapse>
                                        </div>
                                    )}
                                </Panel>
                            ))}
                        </Collapse>
                    </Card>
                )}
                
                {/* Execution history section */}
                {sfn.history && sfn.history.length > 0 && (
                    <Card title="Execution History">
                        <Timeline mode="left">
                            {sfn.history.map((event: any, index: number) => (
                                <Timeline.Item 
                                    key={index}
                                    label={formatTimestamp(new Date(event.timestamp).getTime())}
                                >
                                    <Space direction="vertical">
                                        <Text strong>{event.type}</Text>
                                        {event.details && (
                                            <Button 
                                                type="link" 
                                                size="small"
                                                onClick={() => showRawDataModal(event.details, `Event Details: ${event.type}`)}
                                            >
                                                View Details
                                            </Button>
                                        )}
                                    </Space>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Card>
                )}
                
                {/* Input/Output section */}
                <Row gutter={16} style={{ marginTop: 16 }}>
                    <Col span={12}>
                        <Card title="Input">
                            <Button 
                                type="primary" 
                                onClick={() => showRawDataModal(sfn.input, 'Step Function Input')}
                                style={{ width: '100%' }}
                            >
                                View Input
                            </Button>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title="Output">
                            <Button 
                                type="primary" 
                                onClick={() => showRawDataModal(sfn.output, 'Step Function Output')}
                                style={{ width: '100%' }}
                                disabled={!sfn.output}
                            >
                                View Output
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    };

    // Add this function to handle execution actions
    const handleExecutionAction = async () => {
        if (!selectedExecution) return;
        
        setExecutionActionLoading(true);
        try {
            // Construct the API endpoint based on your backend
            const endpoint = actionType === 'cancel' 
                ? '/api/step-functions/cancel-execution'
                : '/api/step-functions/send-task-success';
            
            const payload = actionType === 'cancel'
                ? { executionArn: selectedExecution.execution_arn }
                : { 
                    taskToken: selectedExecution.waiting_in?.task_token || '',
                    output: callbackInput || JSON.stringify({ success: true })
                  };
              
            await axios.post(endpoint, payload);
            
            message.success(`Successfully ${actionType === 'cancel' ? 'cancelled execution' : 'sent callback'}`);
            setExecutionActionModalVisible(false);
            
            // Refresh the data
            queryResult.refetch();
        } catch (error: any) {
            console.error('Error performing execution action:', error);
            message.error(`Failed to ${actionType}: ${error.message}`);
        } finally {
            setExecutionActionLoading(false);
        }
    };

    // Add this function to show the execution action modal
    const showExecutionActionModal = (execution: any, type: 'cancel' | 'callback') => {
        setSelectedExecution(execution);
        setActionType(type);
        if (type === 'callback') {
            // Initialize with a default success response
            setCallbackInput(JSON.stringify({ success: true }, null, 2));
        } else {
            setCallbackInput('');
        }
        setExecutionActionModalVisible(true);
    };

    return (
        <Show isLoading={isLoading}>
            <div style={{ background: '#f0f2f5', padding: '16px', borderRadius: '8px', marginBottom: '16px' }}>
                <Row gutter={16} align="middle">
                    <Col span={18}>
                        <Title level={3} style={{ margin: 0 }}>Work Item Details</Title>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                        <Space>
                            <Tag color={colors.environment[record?.env as keyof typeof colors.environment] || 'default'} style={{ fontSize: '14px', padding: '4px 8px' }}>
                                {record?.env?.toUpperCase()}
                            </Tag>
                            <Tag color={colors.region[record?.region as keyof typeof colors.region] || 'default'} style={{ fontSize: '14px', padding: '4px 8px' }}>
                                {record?.region}
                            </Tag>
                            <Tag color={colors.status[record?.status as keyof typeof colors.status] || 'default'} style={{ fontSize: '14px', padding: '4px 8px' }}>
                                {record?.status?.replace(/_/g, ' ').toUpperCase()}
                            </Tag>
                        </Space>
                    </Col>
                </Row>
            </div>
            
            <Tabs activeKey={activeTab} onChange={setActiveTab} type="card" size="large" style={{ marginBottom: '16px' }}>
                <TabPane tab="Overview" key="overview">
                    <WorkItemInfo record={record} />
                    
                    {/* Add Step Function Status Summary to Overview tab */}
                    {record?.step_function_status && (
                        <Row gutter={16} style={{ marginBottom: '16px' }}>
                            <Col span={24}>
                                <Card size="small">
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <Space align="center">
                                            <Text strong>Step Function:</Text>
                                            <Tag icon={getStepFunctionStatusIcon(record.step_function_status.status)} 
                                                 color={getStepFunctionStatusColor(record.step_function_status.status)}>
                                                {record.step_function_status.status}
                                            </Tag>
                                            {record.step_function_status.current_state && (
                                                <Text>
                                                    Current state: <Text strong>{record.step_function_status.current_state.name}</Text>
                                                </Text>
                                            )}
                                        </Space>
                                        
                                        {record.step_function_status.waiting_in && (
                                            <Text>
                                                Waiting in: <Text strong>{record.step_function_status.waiting_in.state}</Text>
                                                {record.step_function_status.waiting_in.type && (
                                                    <Text type="secondary"> ({record.step_function_status.waiting_in.type})</Text>
                                                )}
                                            </Text>
                                        )}
                                        
                                        {record.step_function_status.current_state?.active_map_runs && 
                                         record.step_function_status.current_state.active_map_runs.length > 0 && (
                                            <Space>
                                                <Text>Map runs:</Text>
                                                <Tag color="blue">
                                                    {record.step_function_status.current_state.total_iterations} total
                                                </Tag>
                                                <Tag color="green">
                                                    {record.step_function_status.current_state.completed_iterations} completed
                                                </Tag>
                                                <Tag color="processing">
                                                    {record.step_function_status.current_state.running_iterations} running
                                                </Tag>
                                                {record.step_function_status.current_state.failed_iterations > 0 && (
                                                    <Tag color="error">
                                                        {record.step_function_status.current_state.failed_iterations} failed
                                                    </Tag>
                                                )}
                                            </Space>
                                        )}
                                    </Space>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    
                    <Row gutter={16}>
                        <Col span={12}>
                            <Card title="Basic Information" bordered={false}>
                                <Descriptions column={1} bordered size="small">
                                    <Descriptions.Item label="Lock Value">{record?.lock_value}</Descriptions.Item>
                                    <Descriptions.Item label="Table">{record?.table?.split('process-transactions-mgmt-')}</Descriptions.Item>
                                    <Descriptions.Item label="Action">{record?.action}</Descriptions.Item>
                                    <Descriptions.Item label="Status">{record?.status}</Descriptions.Item>
                                    <Descriptions.Item label="Last Updated Reason">{record?.last_updated_action_reason}</Descriptions.Item>
                                    <Descriptions.Item label="Last Updated By">{record?.last_updated_by}</Descriptions.Item>
                                    <Descriptions.Item label="Last Updated">
                                        {formatTimestamp(record?.last_updated_timestamp || 0)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Created">
                                        {formatTimestamp(record?.create_in_lock_table_timestamp || 0)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Retries">{record?.retries || 0}</Descriptions.Item>
                                    <Descriptions.Item label="Include IDs">{record?.moralis_includes?.include_ids?.join(', ')}</Descriptions.Item>
                                    <Descriptions.Item label="SFN Execution ID">{record?.sfn_execution_id || 'N/A'} <Link to={`https://${record?.region}.console.aws.amazon.com/states/home?region=${record?.region}#/executions/details/${record?.sfn_execution_id}`} target="_blank">View in AWS</Link></Descriptions.Item>

                                </Descriptions>
                            </Card>
                        </Col>
                        
                        <Col span={12}>
                            <Card title="Transaction Information" bordered={false}>
                                <Descriptions column={1} bordered size="small">
                                    <Descriptions.Item label="Chain">
                                        <Tag>{record?.chain}</Tag> (ID: <Tag>{record?.chain_id}</Tag>)
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Address">
                                        <Tag>{record?.address}</Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Hash">
                                        <Tag>{record?.hash}</Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Block Number">
                                        <Tag>{record?.block_number}</Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Block Timestamp">
                                        {record?.block_timestamp}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Message ID">
                                        {record?.message_id}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Col>
                    </Row>
                    
                    <Divider />
                    
                    <Row gutter={16}>
                        <Col span={24}>
                            {getIncludesSummary()}
                        </Col>
                    </Row>
                    
                    <Divider />
                    
                    <Row gutter={16}>
                        <Col span={24}>
                            <Card title="Organization & Tenant Information" bordered={false}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Descriptions title="Organization" bordered size="small">
                                            <Descriptions.Item label="ID">{record?.org_id}</Descriptions.Item>
                                            <Descriptions.Item label="Type">{record?.org_type}</Descriptions.Item>
                                            <Descriptions.Item label="Tier Level">{record?.org_tier_level}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    <Col span={12}>
                                        <Descriptions title="Tenant" bordered size="small">
                                            <Descriptions.Item label="ID">{record?.tenant_id}</Descriptions.Item>
                                            <Descriptions.Item label="Type">{record?.tenant_type}</Descriptions.Item>
                                            <Descriptions.Item label="Tier Level">{record?.tenant_tier_level}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
                
                <TabPane tab="Includes Details" key="includes">
                    <Collapse defaultActiveKey={['summary', 'status', 'original']}>
                        <Panel header="Includes Summary" key="summary">
                            {getIncludesSummary()}
                        </Panel>
                        
                        <Panel header="Include Items by Status" key="status">
                            {getIncludesByStatus()}
                        </Panel>
                        
                        <Panel header="Original Task Results" key="original">
                            {getOriginalTaskResult()}
                        </Panel>
                        
                        <Panel header="All Include Items" key="items">
                            {record?.moralis_includes?.moralis_data && Object.entries(record.moralis_includes.moralis_data).map(([includeId, data]:any) => (
                                <Card 
                                    title={includeId} 
                                    style={{ marginBottom: '16px' }} 
                                    key={includeId}
                                    type="inner"
                                >
                                    <Collapse>
                                        <Panel header="Include Items" key="1">
                                            <AceEditor
                                                mode="json"
                                                theme="monokai"
                                                name={`editor-${includeId}`}
                                                value={JSON.stringify(data.include_items || {}, null, 2)}
                                                readOnly={true}
                                                width="100%"
                                                height="300px"
                                                setOptions={{ useWorker: false }}
                                            />
                                        </Panel>
                                        <Panel header="Task Result" key="2">
                                            <AceEditor
                                                mode="json"
                                                theme="monokai"
                                                name={`editor-result-${includeId}`}
                                                value={JSON.stringify(data.task_result || {}, null, 2)}
                                                readOnly={true}
                                                width="100%"
                                                height="300px"
                                                setOptions={{ useWorker: false }}
                                            />
                                        </Panel>
                                    </Collapse>
                                </Card>
                            ))}
                        </Panel>
                    </Collapse>
                </TabPane>
                
                <TabPane tab="Configuration" key="config">
                    <Row gutter={16}>
                        <Col span={24}>
                            <Collapse defaultActiveKey={['1']}>
                                <Panel header="Moralis Configuration" key="1">
                                    <Descriptions bordered column={1}>
                                        <Descriptions.Item label="From Block">{record?.moralis_from_block || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label="To Block">{record?.moralis_to_block || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label="From Date">{record?.moralis_from_date || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label="To Date">{record?.moralis_to_date || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label="Process Order">{record?.process_order || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label="Use Latest Block">{record?.use_latest_block ? 'Yes' : 'No'}</Descriptions.Item>
                                    </Descriptions>
                                </Panel>
                                
                                <Panel header="Custom Configuration" key="2">
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab="Org Config" key="1">
                                            <AceEditor
                                                mode="json"
                                                theme="monokai"
                                                name="editor-org-config"
                                                value={JSON.stringify(record?.custom_moralis_mgmt_org_config || {}, null, 2)}
                                                readOnly={true}
                                                width="100%"
                                                height="300px"
                                                setOptions={{ useWorker: false }}
                                            />
                                        </TabPane>
                                        <TabPane tab="Tenant Config" key="2">
                                            <AceEditor
                                                mode="json"
                                                theme="monokai"
                                                name="editor-tenant-config"
                                                value={JSON.stringify(record?.custom_moralis_mgmt_tenant_config || {}, null, 2)}
                                                readOnly={true}
                                                width="100%"
                                                height="300px"
                                                setOptions={{ useWorker: false }}
                                            />
                                        </TabPane>
                                        <TabPane tab="Process Config" key="3">
                                            <AceEditor
                                                mode="json"
                                                theme="monokai"
                                                name="editor-process-config"
                                                value={JSON.stringify(record?.custom_process_transactions_org_config || {}, null, 2)}
                                                readOnly={true}
                                                width="100%"
                                                height="300px"
                                                setOptions={{ useWorker: false }}
                                            />
                                        </TabPane>
                                    </Tabs>
                                </Panel>
                                
                                <Panel header="System Information" key="3">
                                    <Descriptions bordered column={1}>
                                        <Descriptions.Item label="SFN Execution ID">{record?.sfn_execution_id || 'N/A'} <Link to={`https://${record?.region}.console.aws.amazon.com/states/home?region=${record?.region}#/executions/details/${record?.sfn_execution_id}`} target="_blank">View in AWS</Link></Descriptions.Item>
                                        <Descriptions.Item label="Initial Lock Creator">{record?.initial_lock_creator || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label="Is Blue">{record?.is_blue ? 'Yes' : 'No'}</Descriptions.Item>
                                        <Descriptions.Item label="Is Custom Runner">{record?.is_custom_runner ? 'Yes' : 'No'}</Descriptions.Item>
                                        <Descriptions.Item label="Is Part of Batch">{record?.is_part_of_batch ? 'Yes' : 'No'}</Descriptions.Item>
                                        <Descriptions.Item label="TTL">{record?.ttl || 'N/A'}</Descriptions.Item>
                                    </Descriptions>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </TabPane>
                
                <TabPane 
                    tab={
                        <span>
                            <HistoryOutlined />
                            Step Function
                        </span>
                    } 
                    key="stepfunction"
                >
                    {getStepFunctionDetails()}
                </TabPane>
                
                {/* Original Include Task Results Tab */}
                <TabPane 
                    tab={
                        <span>
                            <FileSearchOutlined />
                            Original Include Task Results
                        </span>
                    } 
                    key="taskresults"
                >
                    <TaskResultTab record={record} showRawDataModal={showRawDataModal} />
                </TabPane>
                
                <TabPane 
                    tab={
                        <span>
                            <HistoryOutlined />
                            Activity Logs
                        </span>
                    } 
                    key="activitylogs"
                >
                    <ActivityLogsTab record={record} showRawDataModal={showRawDataModal} />
                </TabPane>
                
                <TabPane tab="Raw JSON" key="raw">
                    <div style={{ marginBottom: '16px' }}>
                        <Input.Search
                            placeholder="Search in JSON..."
                            allowClear
                            enterButton="Search"
                            size="large"
                            value={jsonSearchText}
                            onChange={(e) => setJsonSearchText(e.target.value)}
                            onSearch={(value) => setJsonSearchText(value)}
                        />
                        <Text type="secondary" style={{ display: 'block', marginTop: '8px' }}>
                            Tip: Press Ctrl+F in the editor for more advanced search options
                        </Text>
                    </div>
                    
                    <Card>
                        <div style={{ position: 'relative' }}>
                            <div 
                                style={{ 
                                    position: 'absolute', 
                                    top: 0, 
                                    right: 0, 
                                    padding: '4px 8px', 
                                    background: '#1890ff', 
                                    color: 'white',
                                    borderRadius: '0 0 0 4px',
                                    zIndex: 1
                                }}
                            >
                                Read Only
                            </div>
                            <AceEditor  
                                style={{width: '100%', height: '800px'}}
                                mode="json"
                                theme="monokai"
                                wrapEnabled={true}
                                value={record ? JSON.stringify(record, null, 2) : '{}'}
                                readOnly={true}
                                name="json_viewer"
                                editorProps={{ $blockScrolling: true }}
                                setOptions={{ 
                                    useWorker: false,
                                    showPrintMargin: false,
                                    highlightActiveLine: true,
                                    highlightGutterLine: true,
                                    showLineNumbers: true,
                                    tabSize: 2,
                                    fontSize: 14
                                }}
                                markers={
                                    jsonSearchText ? [{
                                        startRow: 0,
                                        startCol: 0,
                                        endRow: 1000,
                                        endCol: 100,
                                        className: 'search-highlight',
                                        type: 'text',
                                        inFront: false
                                    }] : []
                                }
                                onLoad={(editor) => {
                                    // Add custom CSS for search highlighting
                                    const style = document.createElement('style');
                                    style.innerHTML = `
                                        .ace_search-highlight {
                                            background-color: rgba(255, 255, 0, 0.3);
                                            position: absolute;
                                        }
                                    `;
                                    document.head.appendChild(style);
                                    
                                    // Set up keyboard shortcuts
                                    editor.commands.addCommand({
                                        name: 'find',
                                        bindKey: {win: 'Ctrl-F', mac: 'Command-F'},
                                        exec: function(editor) {
                                            editor.execCommand('find');
                                        }
                                    });
                                }}
                            />
                        </div>
                    </Card>
                </TabPane>
            </Tabs>

            {/* Execution Action Modal */}
            <Modal
                title={`${actionType === 'cancel' ? 'Cancel Execution' : 'Send Callback'}`}
                open={executionActionModalVisible}
                onCancel={() => setExecutionActionModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setExecutionActionModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button 
                        key="submit" 
                        type="primary" 
                        loading={executionActionLoading}
                        onClick={handleExecutionAction}
                    >
                        {actionType === 'cancel' ? 'Cancel Execution' : 'Send Callback'}
                    </Button>
                ]}
                width={actionType === 'callback' ? 800 : 520}
            >
                {actionType === 'cancel' ? (
                    <Alert
                        message="Warning"
                        description={`Are you sure you want to cancel execution ${selectedExecution?.execution_arn}? This action cannot be undone.`}
                        type="warning"
                        showIcon
                    />
                ) : (
                    <div>
                        <Alert
                            message="Send Task Callback"
                            description={`This will send a task success callback to the waiting task in execution ${selectedExecution?.execution_arn}.`}
                            type="info"
                            showIcon
                            style={{ marginBottom: 16 }}
                        />
                        <Text strong>Callback Payload:</Text>
                        <AceEditor
                            mode="json"
                            theme="monokai"
                            name="callback-input-editor"
                            value={callbackInput}
                            onChange={setCallbackInput}
                            width="100%"
                            height="300px"
                            setOptions={{
                                showPrintMargin: false,
                                tabSize: 2,
                            }}
                        />
                    </div>
                )}
            </Modal>
        </Show>
    );
};

