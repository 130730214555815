import React, { useState } from 'react';
import { Card, Tabs, Typography, Descriptions, Table, Tag, Space, Collapse, Button, Divider, Row, Col, Statistic, Alert, Empty } from 'antd';
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Show } from '@refinedev/antd';
import { IWorkItem } from 'interfaces';
import { FileSearchOutlined, InfoCircleOutlined, CodeOutlined, WalletOutlined, BlockOutlined, TransactionOutlined, KeyOutlined, LockOutlined } from '@ant-design/icons';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

interface TaskResultProps {
    record: any;
    showRawDataModal: (data: any, title: string) => void;
}

// Add these functions before the TaskResultTab component
const findDuplicateTransactionKeys = (taskResults: any[]) => {
    // Collect all transaction keys across all include IDs
    const allTransKeys: string[] = [];
    const transKeyToIncludeMap: Record<string, string[]> = {};
    
    taskResults.forEach(result => {
        result.taskResult.forEach((transactionResult: any) => {
            const transKey = Object.keys(transactionResult)[0];
            if (transKey) {
                allTransKeys.push(transKey);
                
                // Track which include IDs contain this transaction key
                if (!transKeyToIncludeMap[transKey]) {
                    transKeyToIncludeMap[transKey] = [];
                }
                transKeyToIncludeMap[transKey].push(result.includeId);
            }
        });
    });
    
    // Find duplicates
    const duplicates: Record<string, string[]> = {};
    allTransKeys.forEach((key, index) => {
        if (allTransKeys.indexOf(key) !== index && !duplicates[key]) {
            duplicates[key] = transKeyToIncludeMap[key];
        }
    });
    
    return duplicates;
};

// Modify the TaskResultTab component to include duplicate detection
export const TaskResultTab: React.FC<TaskResultProps> = ({ record, showRawDataModal }) => {
    const [activeTab, setActiveTab] = useState<string>("summary");

    if (!record?.moralis_includes?.moralis_data) {
        return (
            <Alert
                message="No Task Result Data"
                description="This work item does not contain any Moralis task result data."
                type="info"
                showIcon
            />
        );
    }

    // Extract task result data
    const moralisData = record.moralis_includes.moralis_data;
    const taskResults = Object.entries(moralisData).map(([includeId, data]: [string, any]) => {
        return {
            includeId,
            taskResult: data.task_result?.origional_task_result || [],
            taskToken: data.task_result?.task_token,
            lockKey: data.task_result?.lock_key,
            lockValue: data.task_result?.lock_value
        };
    });

    // Find duplicate transaction keys
    const duplicateTransKeys = findDuplicateTransactionKeys(taskResults);
    const duplicateCount = Object.keys(duplicateTransKeys).length;

    // Function to check if a transaction key is a duplicate
    const isTransactionKeyDuplicate = (transKey: string) => {
        return duplicateTransKeys[transKey] !== undefined;
    };

    // Function to render include config items
    const renderIncludeConfigItems = (items: any[]) => {
        if (!items || !items.length) return <Text>No items to include</Text>;

        return (
            <Table
                dataSource={items.map((item, index) => ({ ...item, key: index }))}
                columns={[
                    {
                        title: 'Type',
                        dataIndex: 'type',
                        key: 'type',
                        render: (type) => (
                            <Tag color={type === 'WALLET' ? 'blue' : type === 'TRANSACTION' ? 'green' : 'default'}>
                                {type === 'WALLET' ? <WalletOutlined /> : <TransactionOutlined />} {type}
                            </Tag>
                        )
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description'
                    },
                    {
                        title: 'Addresses',
                        dataIndex: 'addresses',
                        key: 'addresses',
                        render: (addresses) => (
                            <Space direction="vertical" size="small">
                                {addresses?.map((address: string, i: number) => (
                                    <Text key={i} copyable={{ text: address }} style={{ fontFamily: 'monospace' }}>
                                        {address.substring(0, 10)}...{address.substring(address.length - 8)}
                                    </Text>
                                ))}
                            </Space>
                        )
                    },
                    {
                        title: 'Chains',
                        dataIndex: 'chains',
                        key: 'chains',
                        render: (chains) => (
                            <Space>
                                {chains?.map((chain: string, i: number) => (
                                    <Tag key={i} color="purple">{chain}</Tag>
                                ))}
                            </Space>
                        )
                    },
                    {
                        title: 'Block/Date Range',
                        key: 'range',
                        render: (_, record) => (
                            <Space direction="vertical" size={0}>
                                {record.block_number && <Text>Block: {record.block_number}</Text>}
                                {record.from_block && <Text>From Block: {record.from_block}</Text>}
                                {record.to_block && <Text>To Block: {record.to_block}</Text>}
                                {record.date && <Text>Date: {record.date}</Text>}
                                {record.from_date && <Text>From Date: {record.from_date}</Text>}
                                {record.to_date && <Text>To Date: {record.to_date}</Text>}
                            </Space>
                        )
                    },
                    {
                        title: 'Timeout',
                        dataIndex: 'timeout',
                        key: 'timeout',
                        render: (timeout) => `${timeout}s`
                    }
                ]}
                pagination={false}
                size="small"
            />
        );
    };

    // Function to count total transaction keys
    const countTotalTransactionKeys = () => {
        return taskResults.reduce((acc, curr) => {
            return acc + curr.taskResult.length;
        }, 0);
    };

    // Function to count total include items
    const countTotalIncludeItems = () => {
        return taskResults.reduce((acc, curr) => {
            let itemCount = 0;
            curr.taskResult.forEach((result: any) => {
                const firstKey = Object.keys(result)[0];
                if (firstKey) {
                    const config = result[firstKey]?.ITEM_INCLUDE_CONFIG;
                    itemCount += config?.ITEMS_TO_INCLUDE?.length || 0;
                }
            });
            return acc + itemCount;
        }, 0);
    };

    // Modify the renderTaskResultSummary function to include duplicates section
    const renderTaskResultSummary = () => {
        if (!taskResults.length) return <Empty description="No task results available" />;

        return (
            <div>
                <Row gutter={16}>
                    <Col span={6}>
                        <Card>
                            <Statistic 
                                title="Total Include IDs" 
                                value={taskResults.length} 
                                prefix={<FileSearchOutlined />} 
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic 
                                title="Total Transaction Keys" 
                                value={countTotalTransactionKeys()} 
                                prefix={<BlockOutlined />} 
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic 
                                title="Include Items" 
                                value={countTotalIncludeItems()} 
                                prefix={<InfoCircleOutlined />} 
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic 
                                title="Duplicate Keys" 
                                value={duplicateCount} 
                                valueStyle={{ color: duplicateCount > 0 ? '#cf1322' : '#3f8600' }}
                                prefix={<KeyOutlined />} 
                            />
                        </Card>
                    </Col>
                </Row>

                {/* Add Duplicates Section if duplicates exist */}
                {duplicateCount > 0 && (
                    <>
                        <Divider orientation="left">Duplicate Transaction Keys</Divider>
                        <Alert 
                            message={`Found ${duplicateCount} duplicate transaction keys across include IDs`}
                            type="warning" 
                            showIcon
                            style={{ marginBottom: '16px' }}
                        />
                        <Table
                            dataSource={Object.entries(duplicateTransKeys).map(([key, includeIds], index) => ({
                                key: index,
                                transactionKey: key,
                                includeIds,
                                occurrences: includeIds.length
                            }))}
                            columns={[
                                {
                                    title: 'Transaction Key',
                                    dataIndex: 'transactionKey',
                                    key: 'transactionKey',
                                    render: (text) => <Text copyable style={{ wordBreak: 'break-all' }}>{text}</Text>
                                },
                                {
                                    title: 'Occurrences',
                                    dataIndex: 'occurrences',
                                    key: 'occurrences',
                                    render: (count) => <Tag color="red">{count}</Tag>
                                },
                                {
                                    title: 'Include IDs',
                                    dataIndex: 'includeIds',
                                    key: 'includeIds',
                                    render: (includeIds) => (
                                        <Space direction="vertical">
                                            {includeIds.map((id: string, i: number) => (
                                                <Tag key={i} color="blue">{id}</Tag>
                                            ))}
                                        </Space>
                                    )
                                }
                            ]}
                            pagination={false}
                            size="small"
                        />
                    </>
                )}

                <Divider orientation="left">Include IDs</Divider>
                
                <Collapse>
                    {taskResults.map((result, index) => {
                        if (!result.taskResult.length) return null;
                        
                        return (
                            <Panel 
                                header={
                                    <Space>
                                        <Text strong>{result.includeId}</Text>
                                        <Tag color="blue">{result.taskResult.length} transaction keys</Tag>
                                    </Space>
                                } 
                                key={index}
                            >
                                <Descriptions bordered column={1} size="small">
                                    <Descriptions.Item label="Include ID">
                                        <Text copyable>{result.includeId}</Text>
                                    </Descriptions.Item>
                                    
                                    {result.taskToken && (
                                        <Descriptions.Item label={<Space><KeyOutlined /> Task Token</Space>}>
                                            <Text copyable style={{ wordBreak: 'break-all' }}>{result.taskToken}</Text>
                                        </Descriptions.Item>
                                    )}
                                    
                                    {result.lockKey && (
                                        <Descriptions.Item label={<Space><LockOutlined /> Lock Key</Space>}>
                                            <Text copyable>{result.lockKey}</Text>
                                        </Descriptions.Item>
                                    )}
                                    
                                    {result.lockValue && (
                                        <Descriptions.Item label={<Space><LockOutlined /> Lock Value</Space>}>
                                            <Text copyable>{result.lockValue}</Text>
                                        </Descriptions.Item>
                                    )}
                                </Descriptions>
                                
                                <Divider orientation="left">Transaction Keys</Divider>
                                
                                <Collapse>
                                    {result.taskResult.map((transactionResult: any, transIndex: number) => {
                                        const transKey = Object.keys(transactionResult)[0];
                                        if (!transKey) return null;
                                        
                                        const config = transactionResult[transKey]?.ITEM_INCLUDE_CONFIG;
                                        if (!config) return null;
                                        
                                        // Check if this transaction key is a duplicate
                                        const isDuplicate = isTransactionKeyDuplicate(transKey);
                                        
                                        return (
                                            <Panel 
                                                header={
                                                    <Space>
                                                        <Text strong>Transaction Key {transIndex + 1}</Text>
                                                        <Tag color="green">{config?.ITEMS_TO_INCLUDE?.length || 0} items</Tag>
                                                        {isDuplicate && (
                                                            <Tag color="red">Duplicate</Tag>
                                                        )}
                                                    </Space>
                                                } 
                                                key={transIndex}
                                                style={isDuplicate ? { borderLeft: '3px solid #ff4d4f' } : {}}
                                            >
                                                <Descriptions bordered column={1} size="small">
                                                    <Descriptions.Item label="Transaction Key">
                                                        <Text 
                                                            copyable 
                                                            style={{ 
                                                                wordBreak: 'break-all',
                                                                color: isDuplicate ? '#ff4d4f' : undefined
                                                            }}
                                                        >
                                                            {transKey}
                                                        </Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Pre-Process Enabled">
                                                        {config?.enable_pre_process ? 'Yes' : 'No'}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Process Enabled">
                                                        {config?.enable_process ? 'Yes' : 'No'}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Post-Process Enabled">
                                                        {config?.enable_post_process ? 'Yes' : 'No'}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                
                                                <Divider orientation="left">Items to Include</Divider>
                                                {renderIncludeConfigItems(config?.ITEMS_TO_INCLUDE || [])}
                                            </Panel>
                                        );
                                    })}
                                </Collapse>
                            </Panel>
                        );
                    })}
                </Collapse>
            </div>
        );
    };

    // Function to render detailed JSON view
    const renderDetailedView = () => {
        return (
            <Card>
                <AceEditor
                    mode="json"
                    theme="monokai"
                    name="task-result-editor"
                    value={JSON.stringify(record.moralis_includes.moralis_data, null, 2)}
                    readOnly
                    width="100%"
                    height="600px"
                    setOptions={{
                        showPrintMargin: false,
                        tabSize: 2,
                    }}
                />
            </Card>
        );
    };

    return (
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane 
                tab={
                    <span>
                        <InfoCircleOutlined />
                        Summary
                    </span>
                } 
                key="summary"
            >
                {renderTaskResultSummary()}
            </TabPane>
            <TabPane 
                tab={
                    <span>
                        <CodeOutlined />
                        Detailed View
                    </span>
                } 
                key="detailed"
            >
                {renderDetailedView()}
            </TabPane>
        </Tabs>
    );
};

export const TaskResultShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IWorkItem>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const [activeTab, setActiveTab] = useState<string>("summary");

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!record?.moralis_includes?.moralis_data) {
        return (
            <Show title="Task Result">
                <Alert
                    message="No Task Result Data"
                    description="This work item does not contain any Moralis task result data."
                    type="info"
                    showIcon
                />
            </Show>
        );
    }

    // Extract task result data
    const moralisData = record.moralis_includes.moralis_data;
    const taskResults = Object.entries(moralisData).map(([includeId, data]: [string, any]) => {
        return {
            includeId,
            taskResult: data.task_result?.origional_task_result || [],
            taskToken: data.task_result?.task_token,
            lockKey: data.task_result?.lock_key,
            lockValue: data.task_result?.lock_value
        };
    });

    // Find duplicate transaction keys
    const duplicateTransKeys = findDuplicateTransactionKeys(taskResults);
    const duplicateCount = Object.keys(duplicateTransKeys).length;

    // Function to check if a transaction key is a duplicate
    const isTransactionKeyDuplicate = (transKey: string) => {
        return duplicateTransKeys[transKey] !== undefined;
    };

    // Function to render include config items
    const renderIncludeConfigItems = (items: any[]) => {
        if (!items || !items.length) return <Text>No items to include</Text>;

        return (
            <Table
                dataSource={items.map((item, index) => ({ ...item, key: index }))}
                columns={[
                    {
                        title: 'Type',
                        dataIndex: 'type',
                        key: 'type',
                        render: (type) => (
                            <Tag color={type === 'WALLET' ? 'blue' : type === 'TRANSACTION' ? 'green' : 'default'}>
                                {type === 'WALLET' ? <WalletOutlined /> : <TransactionOutlined />} {type}
                            </Tag>
                        )
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description'
                    },
                    {
                        title: 'Addresses',
                        dataIndex: 'addresses',
                        key: 'addresses',
                        render: (addresses) => (
                            <Space direction="vertical" size="small">
                                {addresses?.map((address: string, i: number) => (
                                    <Text key={i} copyable={{ text: address }} style={{ fontFamily: 'monospace' }}>
                                        {address.substring(0, 10)}...{address.substring(address.length - 8)}
                                    </Text>
                                ))}
                            </Space>
                        )
                    },
                    {
                        title: 'Chains',
                        dataIndex: 'chains',
                        key: 'chains',
                        render: (chains) => (
                            <Space>
                                {chains?.map((chain: string, i: number) => (
                                    <Tag key={i} color="purple">{chain}</Tag>
                                ))}
                            </Space>
                        )
                    },
                    {
                        title: 'Block/Date Range',
                        key: 'range',
                        render: (_, record) => (
                            <Space direction="vertical" size={0}>
                                {record.block_number && <Text>Block: {record.block_number}</Text>}
                                {record.from_block && <Text>From Block: {record.from_block}</Text>}
                                {record.to_block && <Text>To Block: {record.to_block}</Text>}
                                {record.date && <Text>Date: {record.date}</Text>}
                                {record.from_date && <Text>From Date: {record.from_date}</Text>}
                                {record.to_date && <Text>To Date: {record.to_date}</Text>}
                            </Space>
                        )
                    },
                    {
                        title: 'Timeout',
                        dataIndex: 'timeout',
                        key: 'timeout',
                        render: (timeout) => `${timeout}s`
                    }
                ]}
                pagination={false}
                size="small"
            />
        );
    };

    // Function to count total transaction keys
    const countTotalTransactionKeys = () => {
        return taskResults.reduce((acc, curr) => {
            return acc + curr.taskResult.length;
        }, 0);
    };

    // Function to count total include items
    const countTotalIncludeItems = () => {
        return taskResults.reduce((acc, curr) => {
            let itemCount = 0;
            curr.taskResult.forEach((result: any) => {
                const firstKey = Object.keys(result)[0];
                if (firstKey) {
                    const config = result[firstKey]?.ITEM_INCLUDE_CONFIG;
                    itemCount += config?.ITEMS_TO_INCLUDE?.length || 0;
                }
            });
            return acc + itemCount;
        }, 0);
    };

    // Modify the renderTaskResultSummary function to include duplicates section
    const renderTaskResultSummary = () => {
        if (!taskResults.length) return <Empty description="No task results available" />;

        return (
            <div>
                <Row gutter={16}>
                    <Col span={6}>
                        <Card>
                            <Statistic 
                                title="Total Include IDs" 
                                value={taskResults.length} 
                                prefix={<FileSearchOutlined />} 
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic 
                                title="Total Transaction Keys" 
                                value={countTotalTransactionKeys()} 
                                prefix={<BlockOutlined />} 
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic 
                                title="Include Items" 
                                value={countTotalIncludeItems()} 
                                prefix={<InfoCircleOutlined />} 
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic 
                                title="Duplicate Keys" 
                                value={duplicateCount} 
                                valueStyle={{ color: duplicateCount > 0 ? '#cf1322' : '#3f8600' }}
                                prefix={<KeyOutlined />} 
                            />
                        </Card>
                    </Col>
                </Row>

                {/* Add Duplicates Section if duplicates exist */}
                {duplicateCount > 0 && (
                    <>
                        <Divider orientation="left">Duplicate Transaction Keys</Divider>
                        <Alert 
                            message={`Found ${duplicateCount} duplicate transaction keys across include IDs`}
                            type="warning" 
                            showIcon
                            style={{ marginBottom: '16px' }}
                        />
                        <Table
                            dataSource={Object.entries(duplicateTransKeys).map(([key, includeIds], index) => ({
                                key: index,
                                transactionKey: key,
                                includeIds,
                                occurrences: includeIds.length
                            }))}
                            columns={[
                                {
                                    title: 'Transaction Key',
                                    dataIndex: 'transactionKey',
                                    key: 'transactionKey',
                                    render: (text) => <Text copyable style={{ wordBreak: 'break-all' }}>{text}</Text>
                                },
                                {
                                    title: 'Occurrences',
                                    dataIndex: 'occurrences',
                                    key: 'occurrences',
                                    render: (count) => <Tag color="red">{count}</Tag>
                                },
                                {
                                    title: 'Include IDs',
                                    dataIndex: 'includeIds',
                                    key: 'includeIds',
                                    render: (includeIds) => (
                                        <Space direction="vertical">
                                            {includeIds.map((id: string, i: number) => (
                                                <Tag key={i} color="blue">{id}</Tag>
                                            ))}
                                        </Space>
                                    )
                                }
                            ]}
                            pagination={false}
                            size="small"
                        />
                    </>
                )}

                <Divider orientation="left">Include IDs</Divider>
                
                <Collapse>
                    {taskResults.map((result, index) => {
                        if (!result.taskResult.length) return null;
                        
                        return (
                            <Panel 
                                header={
                                    <Space>
                                        <Text strong>{result.includeId}</Text>
                                        <Tag color="blue">{result.taskResult.length} transaction keys</Tag>
                                    </Space>
                                } 
                                key={index}
                            >
                                <Descriptions bordered column={1} size="small">
                                    <Descriptions.Item label="Include ID">
                                        <Text copyable>{result.includeId}</Text>
                                    </Descriptions.Item>
                                    
                                    {result.taskToken && (
                                        <Descriptions.Item label={<Space><KeyOutlined /> Task Token</Space>}>
                                            <Text copyable style={{ wordBreak: 'break-all' }}>{result.taskToken}</Text>
                                        </Descriptions.Item>
                                    )}
                                    
                                    {result.lockKey && (
                                        <Descriptions.Item label={<Space><LockOutlined /> Lock Key</Space>}>
                                            <Text copyable>{result.lockKey}</Text>
                                        </Descriptions.Item>
                                    )}
                                    
                                    {result.lockValue && (
                                        <Descriptions.Item label={<Space><LockOutlined /> Lock Value</Space>}>
                                            <Text copyable>{result.lockValue}</Text>
                                        </Descriptions.Item>
                                    )}
                                </Descriptions>
                                
                                <Divider orientation="left">Transaction Keys</Divider>
                                
                                <Collapse>
                                    {result.taskResult.map((transactionResult: any, transIndex: number) => {
                                        const transKey = Object.keys(transactionResult)[0];
                                        if (!transKey) return null;
                                        
                                        const config = transactionResult[transKey]?.ITEM_INCLUDE_CONFIG;
                                        if (!config) return null;
                                        
                                        // Check if this transaction key is a duplicate
                                        const isDuplicate = isTransactionKeyDuplicate(transKey);
                                        
                                        return (
                                            <Panel 
                                                header={
                                                    <Space>
                                                        <Text strong>Transaction Key {transIndex + 1}</Text>
                                                        <Tag color="green">{config?.ITEMS_TO_INCLUDE?.length || 0} items</Tag>
                                                        {isDuplicate && (
                                                            <Tag color="red">Duplicate</Tag>
                                                        )}
                                                    </Space>
                                                } 
                                                key={transIndex}
                                                style={isDuplicate ? { borderLeft: '3px solid #ff4d4f' } : {}}
                                            >
                                                <Descriptions bordered column={1} size="small">
                                                    <Descriptions.Item label="Transaction Key">
                                                        <Text 
                                                            copyable 
                                                            style={{ 
                                                                wordBreak: 'break-all',
                                                                color: isDuplicate ? '#ff4d4f' : undefined
                                                            }}
                                                        >
                                                            {transKey}
                                                        </Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Pre-Process Enabled">
                                                        {config?.enable_pre_process ? 'Yes' : 'No'}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Process Enabled">
                                                        {config?.enable_process ? 'Yes' : 'No'}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Post-Process Enabled">
                                                        {config?.enable_post_process ? 'Yes' : 'No'}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                
                                                <Divider orientation="left">Items to Include</Divider>
                                                {renderIncludeConfigItems(config?.ITEMS_TO_INCLUDE || [])}
                                            </Panel>
                                        );
                                    })}
                                </Collapse>
                            </Panel>
                        );
                    })}
                </Collapse>
            </div>
        );
    };

    // Function to render detailed JSON view
    const renderDetailedView = () => {
        return (
            <Card>
                <AceEditor
                    mode="json"
                    theme="monokai"
                    name="task-result-editor"
                    value={JSON.stringify(record.moralis_includes.moralis_data, null, 2)}
                    readOnly
                    width="100%"
                    height="600px"
                    setOptions={{
                        showPrintMargin: false,
                        tabSize: 2,
                    }}
                />
            </Card>
        );
    };

    return (
        <Show title="Task Result">
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <TabPane 
                    tab={
                        <span>
                            <InfoCircleOutlined />
                            Summary
                        </span>
                    } 
                    key="summary"
                >
                    {renderTaskResultSummary()}
                </TabPane>
                <TabPane 
                    tab={
                        <span>
                            <CodeOutlined />
                            Detailed View
                        </span>
                    } 
                    key="detailed"
                >
                    {renderDetailedView()}
                </TabPane>
            </Tabs>
        </Show>
    );
}; 