import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
    CreateButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Tooltip, Divider, Button, Radio, Tag, Input, message, Modal, Select, Form, InputNumber, Switch } from "antd";
import { SearchOutlined, ReloadOutlined, SyncOutlined } from '@ant-design/icons';

import { IWorkItem } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useCreate, useCustomMutation } from "@refinedev/core";
const { Title, Text } = Typography;

export const WorkItemsList: React.FC<IResourceComponentsProps> = () => {
    const CheckboxGroup = Checkbox.Group;
    const [searchAddress, setSearchAddress] = useState<string>("0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326");
    const [finalSearchAddress, setFinalSearchAddress] = useState<string>("0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326");
    const [isInvalidSearchAddress, setIsInvalidSearchAddress] = useState<boolean>(false);
    const [environment, setEnvironment] = useState<string>("sandbox");
    const [searchText, setSearchText] = useState<string>("");
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    const [selectedStatus, setSelectedStatus] = useState<string>("ready_to_be_processed");
    const [customStatus, setCustomStatus] = useState<string>("");
    const [isCustomStatus, setIsCustomStatus] = useState<boolean>(false);
    const [isStepFunctionModalVisible, setIsStepFunctionModalVisible] = useState<boolean>(false);
    const [isExecutingStepFunction, setIsExecutingStepFunction] = useState<boolean>(false);
    const [stepFunctionForm] = Form.useForm();

    const all_possible_regions = [
        {
            "label": "us-west-2",
            "value": "us-west-2"
        },
        {
            "label": "us-east-1",
            "value": "us-east-1"
        },
        {
            "label": "eu-central-1",
            "value": "eu-central-1"
        },
        {
            "label": "eu-west-1",
            "value": "eu-west-1"
        },
    ]

    const environments = [
        { label: "Production", value: "prd" },
        { label: "Sandbox", value: "sandbox" },
    ];

    const plainOptions = all_possible_regions
    const defaultCheckedList = ["us-west-2"] // all_possible_regions.map(a => a.value);

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
    const [searchCheckedList, setSearchCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);

    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
    
    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
    };
    
    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? plainOptions.map(a => a.value) : []);
    };

    const onEnvironmentChange = (value: string) => {
        setEnvironment(value);
    };
    
    const {
        user,
    } = useAuth0();
        
    const { tableProps, tableQueryResult } = useTable<IWorkItem>({
        resource: "work-items",
        meta: { 
            "regions": searchCheckedList,
            "environment": environment,
        },
    });

    const refreshTableResults = () => {     
        setSearchCheckedList(checkedList)   
        tableQueryResult.refetch()
    };

    // Function to filter data locally based on search text
    const getFilteredData = () => {
        if (!searchText || !tableQueryResult?.data?.data) {
            return tableQueryResult?.data?.data;
        }
        
        const searchLower = searchText.toLowerCase();
        return tableQueryResult?.data?.data.filter((item) => {
            // Search across multiple fields
            return (
                (item.lock_value && item.lock_value.toString().toLowerCase().includes(searchLower)) ||
                (item.table && item.table.toLowerCase().includes(searchLower)) ||
                (item.chain && item.chain.toLowerCase().includes(searchLower)) ||
                (item.address && item.address.toLowerCase().includes(searchLower)) ||
                (item.hash && item.hash.toLowerCase().includes(searchLower)) ||
                (item.region && item.region.toLowerCase().includes(searchLower)) ||
                (item.status && item.status.toLowerCase().includes(searchLower)) ||
                (item.last_updated_action_reason && item.last_updated_action_reason.toLowerCase().includes(searchLower)) ||
                (item.message_id && item.message_id.toLowerCase().includes(searchLower))
            );
        });
    };

    // Custom mutation for updating work items
    const { mutate: updateWorkItems, isLoading: isUpdateLoading } = useCustomMutation();
    
    // Handle row selection
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: React.Key[]) => {
            setSelectedRowKeys(selectedKeys);
        },
    };
    
    // Function to refresh the table data
    const handleRefreshData = async () => {
        setIsRefreshing(true);
        try {
            await tableQueryResult.refetch();
            message.success('Data refreshed successfully');
        } catch (error) {
            message.error('Failed to refresh data');
            console.error('Refresh error:', error);
        } finally {
            setIsRefreshing(false);
        }
    };
    
    // Predefined status options
    const statusOptions = [
        { label: "Ready to be processed", value: "ready_to_be_processed" },
        { label: "In progress", value: "in_progress" },
        { label: "To be deleted", value: "to_be_deleted" },
        { label: "Custom status", value: "custom" }
    ];
    
    // Function to update selected work items to the chosen status
    const handleUpdateStatus = () => {
        if (selectedRowKeys.length === 0) {
            message.warning('Please select at least one work item to update');
            return;
        }
        
        // Determine which status to use
        const statusToApply = isCustomStatus ? customStatus : selectedStatus;
        
        if (isCustomStatus && !customStatus.trim()) {
            message.warning('Please enter a custom status');
            return;
        }
        
        Modal.confirm({
            title: 'Update Work Items',
            content: (
                <div>
                    <p>{`Are you sure you want to update ${selectedRowKeys.length} work item(s) to status "${statusToApply}"?`}</p>
                    <div style={{ marginTop: '16px' }}>
                        <Select
                            style={{ width: '100%', marginBottom: '8px' }}
                            options={statusOptions}
                            value={isCustomStatus ? 'custom' : selectedStatus}
                            onChange={(value) => {
                                if (value === 'custom') {
                                    setIsCustomStatus(true);
                                } else {
                                    setIsCustomStatus(false);
                                    setSelectedStatus(value);
                                }
                            }}
                        />
                        
                        {isCustomStatus && (
                            <Input
                                placeholder="Enter custom status"
                                value={customStatus}
                                onChange={(e) => setCustomStatus(e.target.value)}
                                style={{ marginTop: '8px' }}
                            />
                        )}
                    </div>
                </div>
            ),
            okText: 'Yes, Update',
            cancelText: 'Cancel',
            onOk: async () => {
                setIsUpdating(true);
                
                // Find the selected items from the data
                const selectedItems = tableQueryResult?.data?.data.filter(
                    (item) => selectedRowKeys.includes(item.table + "_" + item.lock_value)
                );
                
                if (!selectedItems || selectedItems.length === 0) {
                    message.error('Could not find selected items in data');
                    setIsUpdating(false);
                    return;
                }
                
                // Prepare the update payload
                const updates = selectedItems.map(item => ({
                    region: item.region,
                    table_name: item.table,
                    pk: item.lock_key, // Use the item's lock_key as pk
                    sk: item.lock_value,
                    updates: {
                        status: statusToApply,
                        last_updated: new Date().toISOString()
                    }
                }));
                
                try {
                    // Use fetch directly for more control over the response
                    const response = await fetch("https://us.api.sandbox.authe.io/internal/tools/process-transactions-mgmt-debug-tool/blue/ANY/US/v0/work-items/batch", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${token.__raw}`,
                            "Source-Platform": "admin-portal",
                            "Source-Region": "us-west-2",
                            "Destination-Region": "us-west-2",
                        },
                        body: JSON.stringify({
                            updates: updates
                        })
                    });
                    
                    const responseData = await response.json();
                    
                    if (responseData.ok == true && responseData.results) {
                        const errors = responseData.results.filter((item: any) => item.status === "error");
                        
                        if (errors.length > 0) {
                            // Some items failed to update
                            message.error(`Failed to update ${errors.length} item(s). See console for details.`);
                            console.error("Update errors:", errors);
                            
                            if (errors.length < responseData.results.length) {
                                // Some items were updated successfully
                                message.success(`Successfully updated ${responseData.results.length - errors.length} item(s)`);
                            }
                        } else {
                            // All items updated successfully
                            message.success(`Successfully updated ${updates.length} work item(s)`);
                        }
                    } else {
                        // General error
                        message.error("Failed to update work items");
                        console.error("Update response:", responseData);
                    }
                    
                    // Clear selection and refresh data
                    setSelectedRowKeys([]);
                    await tableQueryResult.refetch(); // Ensure table is refreshed after update
                } catch (error) {
                    console.error("Error updating work items:", error);
                    message.error("An error occurred while updating work items");
                } finally {
                    setIsUpdating(false);
                }
            },
        });
    };

    // Add this new function before the return statement
    const handleBatchDelete = () => {
        if (selectedRowKeys.length === 0) {
            message.warning('Please select at least one work item to delete');
            return;
        }

        Modal.confirm({
            title: 'Delete Work Items',
            content: `Are you sure you want to delete ${selectedRowKeys.length} selected work item(s)?`,
            okText: 'Yes, Delete',
            okButtonProps: { danger: true },
            cancelText: 'Cancel',
            onOk: async () => {
                setIsUpdating(true);
                
                // Find the selected items from the data
                const selectedItems = tableQueryResult?.data?.data.filter(
                    (item) => selectedRowKeys.includes(item.table + "_" + item.lock_value)
                );
                
                if (!selectedItems || selectedItems.length === 0) {
                    message.error('Could not find selected items in data');
                    setIsUpdating(false);
                    return;
                }
                
                // Prepare the update payload with "to_be_deleted" status
                const updates = selectedItems.map(item => ({
                    region: item.region,
                    table_name: item.table,
                    pk: item.lock_key,
                    sk: item.lock_value,
                    updates: {
                        status: "to_be_deleted",
                        last_updated: new Date().toISOString()
                    }
                }));
                
                try {
                    const response = await fetch("https://us.api.sandbox.authe.io/internal/tools/process-transactions-mgmt-debug-tool/blue/ANY/US/v0/work-items/batch", {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${token.__raw}`,
                            "Source-Platform": "admin-portal",
                            "Source-Region": "us-west-2",
                            "Destination-Region": "us-west-2",
                        },
                        body: JSON.stringify({
                            updates: updates
                        })
                    });
                    
                    const responseData = await response.json();
                    
                    if (responseData.ok == true && responseData.results) {
                        const errors = responseData.results.filter((item: any) => item.status === "error");
                        
                        if (errors.length > 0) {
                            message.error(`Failed to delete ${errors.length} item(s). See console for details.`);
                            console.error("Delete errors:", errors);
                            
                            if (errors.length < responseData.results.length) {
                                message.success(`Successfully marked ${responseData.results.length - errors.length} item(s) for deletion`);
                            }
                        } else {
                            message.success(`Successfully marked ${updates.length} work item(s) for deletion`);
                        }
                    } else {
                        message.error("Failed to delete work items");
                        console.error("Delete response:", responseData);
                    }
                    
                    setSelectedRowKeys([]);
                    await tableQueryResult.refetch();
                } catch (error) {
                    console.error("Error deleting work items:", error);
                    message.error("An error occurred while deleting work items");
                } finally {
                    setIsUpdating(false);
                }
            },
        });
    };

    const handleStartStepFunction = () => {
        // Set default values
        stepFunctionForm.setFieldsValue({
            pipeline_name: 'pre-process',
            environment: environment, // Use current environment
            region: searchCheckedList[0] || 'us-west-2', // Use first selected region
            is_blue: true,
            batch_size: 100,
            max_batches: 5
        });
        
        setIsStepFunctionModalVisible(true);
    };

    const executeStepFunction = async (values: any) => {
        setIsExecutingStepFunction(true);
        
        try {
            // Prepare the payload
            const payload = {
                pipeline_name: values.pipeline_name,
                environment: values.environment,
                region: values.region,
                is_blue: values.is_blue,
                input: {
                    batch_size: values.batch_size,
                    max_batches: values.max_batches
                }
            };
            
            // Execute the step function
            const response = await fetch("https://us.api.sandbox.authe.io/internal/tools/process-transactions-mgmt-debug-tool/blue/ANY/US/v0/step-functions/execute", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token.__raw}`,
                    "Source-Platform": "admin-portal",
                    "Source-Region": values.region,
                },
                body: JSON.stringify(payload)
            });
            
            const responseData = await response.json();
            
            if (responseData.ok === true) {
                message.success(`Successfully started step function execution: ${responseData.execution_arn || 'No ARN returned'}`);
                setIsStepFunctionModalVisible(false);
            } else {
                message.error(`Failed to start step function: ${responseData.message || 'Unknown error'}`);
                console.error("Step function execution error:", responseData);
            }
        } catch (error) {
            console.error("Error executing step function:", error);
            message.error("An error occurred while starting the step function");
        } finally {
            setIsExecutingStepFunction(false);
        }
    };

    const StepFunctionModal = () => {
        const pipelineOptions = [
            { label: "Pre-Process", value: "pre-process" },
            { label: "Process", value: "process" },
            { label: "Post-Process", value: "post-process" }
        ];
        
        return (
            <Modal
                title="Start Step Function Execution"
                open={isStepFunctionModalVisible}
                onCancel={() => setIsStepFunctionModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setIsStepFunctionModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button 
                        key="submit" 
                        type="primary" 
                        loading={isExecutingStepFunction}
                        onClick={() => stepFunctionForm.submit()}
                    >
                        Execute
                    </Button>
                ]}
                width={600}
            >
                <Form
                    form={stepFunctionForm}
                    layout="vertical"
                    onFinish={executeStepFunction}
                    initialValues={{
                        pipeline_name: 'pre-process',
                        environment: environment,
                        region: searchCheckedList[0] || 'us-west-2',
                        is_blue: true,
                        batch_size: 100,
                        max_batches: 5
                    }}
                >
                    <Form.Item 
                        name="pipeline_name" 
                        label="Pipeline Name" 
                        rules={[{ required: true, message: 'Please select a pipeline' }]}
                    >
                        <Select options={pipelineOptions} />
                    </Form.Item>
                    
                    <Form.Item 
                        name="environment" 
                        label="Environment" 
                        rules={[{ required: true, message: 'Please select an environment' }]}
                    >
                        <Select options={environments} />
                    </Form.Item>
                    
                    <Form.Item 
                        name="region" 
                        label="Region" 
                        rules={[{ required: true, message: 'Please select a region' }]}
                    >
                        <Select options={all_possible_regions} />
                    </Form.Item>
                    
                    <Form.Item 
                        name="is_blue" 
                        label="Is Blue" 
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    
                    <Form.Item label="Input Parameters">
                        <div style={{ background: '#f5f5f5', padding: '16px', borderRadius: '4px' }}>
                            <Form.Item 
                                name="batch_size" 
                                label="Batch Size" 
                                rules={[{ required: true, message: 'Please enter batch size' }]}
                            >
                                <InputNumber min={1} max={1000} style={{ width: '100%' }} />
                            </Form.Item>
                            
                            <Form.Item 
                                name="max_batches" 
                                label="Max Batches" 
                                rules={[{ required: true, message: 'Please enter max batches' }]}
                            >
                                <InputNumber min={1} max={100} style={{ width: '100%' }} />
                            </Form.Item>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        );
    };
    
    return (
        <List headerButtons={({ createButtonProps }) => (
            <>
              {createButtonProps && <CreateButton {...createButtonProps} meta={{ foo: "bar" }} />}
            </>
        )}>
            <div style={{ background: '#f0f2f5', padding: '16px', borderRadius: '8px', marginBottom: '16px' }}>
                <Title level={4} style={{ marginBottom: '16px' }}>Work Items Configuration</Title>
                
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    {/* Environment Selection */}
                    <div>
                        <Title level={5}>Environment:</Title>
                        <Radio.Group 
                            options={environments} 
                            onChange={(e) => onEnvironmentChange(e.target.value)} 
                            value={environment} 
                            optionType="button" 
                            buttonStyle="solid"
                        />
                    </div>
                    
                    {/* Region Selection */}
                    <div>
                        <Title level={5}>Regions:</Title>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Checkbox 
                                indeterminate={indeterminate} 
                                onChange={onCheckAllChange} 
                                checked={checkAll}
                            >
                                Select all regions
                            </Checkbox>
                            <CheckboxGroup 
                                options={plainOptions} 
                                value={checkedList} 
                                onChange={onChange} 
                                style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}
                            />
                        </Space>
                    </div>
                    
                    <Button 
                        type="primary" 
                        onClick={refreshTableResults} 
                        disabled={tableQueryResult?.isFetching}
                        size="large"
                        style={{ width: '200px' }}
                    >
                        Apply Filters
                    </Button>
                </div>
            </div>
            
            {/* Current Environment and Region Indicator */}
            <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Text strong>Current Environment:</Text>
                    <Tag color={environment === 'production' ? 'red' : environment === 'staging' ? 'orange' : 'green'} style={{ fontSize: '14px' }}>
                        {environment.toUpperCase()}
                    </Tag>
                    
                    <Divider type="vertical" />
                    
                    <Text strong>Active Regions:</Text>
                    {searchCheckedList.map((region) => (
                        <Tag 
                            key={region.toString()} 
                            color={
                                region === 'us-west-2' ? 'green' : 
                                region === 'us-east-1' ? 'cyan' : 
                                region === 'eu-central-1' ? 'blue' : 
                                'geekblue'
                            }
                        >
                            {region.toString()}
                        </Tag>
                    ))}
                </div>
                
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button
                        type="primary"
                        onClick={handleStartStepFunction}
                    >
                        Start Step Function
                    </Button>
                    
                    {/* Refresh Button */}
                    <Button
                        icon={<SyncOutlined />}
                        onClick={handleRefreshData}
                        loading={isRefreshing || tableQueryResult.isFetching}
                        disabled={isRefreshing || tableQueryResult.isFetching}
                    >
                        Refresh Data
                    </Button>
                </div>
            </div>
            
            {/* Search Input */}
            <div style={{ marginBottom: '16px' }}>
                <Input
                    placeholder="Search across all fields..."
                    prefix={<SearchOutlined />}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    allowClear
                    style={{ width: '100%' }}
                    size="large"
                />
                {searchText && (
                    <div style={{ marginTop: '8px' }}>
                        <Text type="secondary">
                            Showing {getFilteredData()?.length || 0} of {tableQueryResult?.data?.data?.length || 0} items
                        </Text>
                    </div>
                )}
            </div>

            {/* Bulk Action Buttons */}
            <div style={{ marginBottom: '16px', display: 'flex', gap: '8px', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Button
                        type="primary"
                        onClick={handleUpdateStatus}
                        disabled={selectedRowKeys.length === 0 || isUpdateLoading || isRefreshing}
                        loading={isUpdating}
                        icon={<ReloadOutlined />}
                    >
                        Update Selected Items ({selectedRowKeys.length})
                    </Button>

                    <Button
                        danger
                        type="primary"
                        onClick={handleBatchDelete}
                        disabled={selectedRowKeys.length === 0 || isUpdateLoading || isRefreshing}
                        loading={isUpdating}
                    >
                        Delete Selected Items ({selectedRowKeys.length})
                    </Button>
                    
                    <Select
                        style={{ width: 200 }}
                        options={statusOptions}
                        value={isCustomStatus ? 'custom' : selectedStatus}
                        onChange={(value) => {
                            if (value === 'custom') {
                                setIsCustomStatus(true);
                            } else {
                                setIsCustomStatus(false);
                                setSelectedStatus(value);
                            }
                        }}
                        disabled={selectedRowKeys.length === 0 || isUpdateLoading || isRefreshing}
                    />
                    
                    {isCustomStatus && (
                        <Input
                            placeholder="Enter custom status"
                            value={customStatus}
                            onChange={(e) => setCustomStatus(e.target.value)}
                            style={{ width: 200 }}
                            disabled={selectedRowKeys.length === 0 || isUpdateLoading || isRefreshing}
                        />
                    )}
                </div>
                
                {selectedRowKeys.length > 0 && (
                    <Button 
                        onClick={() => setSelectedRowKeys([])}
                        size="middle"
                    >
                        Clear Selection
                    </Button>
                )}
                
                {(isUpdateLoading || isRefreshing) && <Text type="secondary">Updating data...</Text>}
            </div>

            <Table 
                {...tableProps} 
                rowKey={(record) => record.table + "_" + record.lock_value}
                dataSource={getFilteredData()}
                pagination={{
                    defaultPageSize: 500,
                    showSizeChanger: true,
                }}
                size="middle"
                bordered
                rowSelection={rowSelection}
                loading={tableQueryResult.isFetching || isRefreshing}
            >
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.lock_value - b.lock_value}
                    title="Id" 
                    dataIndex="lock_value" 
                    render={(_: any, record: { lock_value: any; pk: string; }) => {
                        return (
                            <Tooltip title={record?.lock_value}>
                            <TagField
                                value={`${
                                    record?.pk.substring(0,2)
                                }` }
                            />
                            </Tooltip>
                        );
                    }}
                />
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.table - b.table}
                    filters={tableQueryResult?.data?.data.map((
                        { table }) => ({ "text": table, "value": table})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { table: string; }) => record.table.startsWith(value)}
                    title="Table" 
                    dataIndex="table" 
                    render={(_: any, record: { table: any; }) => {
                        let colors = {
                            "pre-processed": "lightblue",
                            "processed": "blue",
                            "post-processed": "darkblue",
                        }
                        return (
                            <TagField
                                color={colors[record?.table.split('process-transactions-mgmt-').pop().split('-transactions-')[0] as keyof typeof colors ]}
                                value={`${
                                    record?.table.split('process-transactions-mgmt-').pop().split('-transactions-')[0]
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.chain - b.chain}
                    filters={tableQueryResult?.data?.data.map((
                        { chain }) => ({ "text": chain, "value": chain})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { chain: string; }) => record.chain.startsWith(value)}
                    title="Chain" 
                    dataIndex="chain" 
                    render={(_: any, record: { chain: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.chain
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.address - b.address}
                    filters={tableQueryResult?.data?.data.map((
                        { address }) => ({ "text": address, "value": address})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { address: string; }) => record.address.startsWith(value)}
                    title="Address" 
                    dataIndex="address" 
                    render={(_: any, record: { address: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.address
                                }` }
                            />
                        );
                    }}
                />    
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.hash - b.hash}
                    filters={tableQueryResult?.data?.data.map((
                        { hash }) => ({ "text": hash, "value": hash})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { hash: string; }) => record.hash.startsWith(value)}
                    title="Hash" 
                    dataIndex="hash" 
                    render={(_: any, record: { hash: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.hash
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.region - b.region}
                    filters={tableQueryResult?.data?.data.map((
                        { region }) => ({ "text": region, "value": region})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { region: string; }) => record.region.startsWith(value)}
                    title="Region" 
                    dataIndex="region" 
                    render={(_: any, record: { region: string; }) => {
                        let colors = {
                            "us-west-2": "green",
                            "us-east-1": "lightgreen",
                            "eu-central-1": "blue",
                            "eu-west-1": "lightblue"
                        }
                        return (
                            <TagField
                                color={colors[record.region as keyof typeof colors ]}
                                value={`${
                                    record?.region
                                }` }
                            />
                        );
                    }}
                />      
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.create_in_lock_table_timestamp - b.create_in_lock_table_timestamp}
                    filters={tableQueryResult?.data?.data.map((
                        { create_in_lock_table_timestamp }:any) => ({ "text": create_in_lock_table_timestamp, "value": create_in_lock_table_timestamp})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: {create_in_lock_table_timestamp: number | undefined; }) =>  String(record.create_in_lock_table_timestamp).startsWith(value)}
                    title="Created in lock table" 
                    dataIndex="create_in_lock_table_timestamp" 
                    render={(_: any, record: { create_in_lock_table_timestamp: any; }) => {
                        return (
                            <DateField value={dayjs.unix(Number(record?.create_in_lock_table_timestamp/1000) || 1)} format="LLL"></DateField>
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.status - b.status}
                    filters={tableQueryResult?.data?.data.map((
                        { status }) => ({ "text": status, "value": status})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { status: string; }) => record.status.startsWith(value)}
                    title="Status" 
                    dataIndex="status" 
                    render={(_: any, record: { status: any; }) => {
                        let colors = {
                            "to_be_deleted": "red",
                            "ready_to_be_processed": "blue",
                            "in_progress": "green",
                        }

                        return (
                            <TagField
                                color={colors[record.status as keyof typeof colors ]}
                                value={`${
                                    record?.status
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.last_updated_action_reason - b.last_updated_action_reason}
                    filters={tableQueryResult?.data?.data.map((
                        { last_updated_action_reason }) => ({ "text": last_updated_action_reason, "value": last_updated_action_reason})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { status: string; }) => record.status.startsWith(value)}
                    title="Last updated reason" 
                    dataIndex="last_updated_action_reason" 
                    render={(_: any, record: { last_updated_action_reason: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.last_updated_action_reason
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.retries - b.retries}
                    filters={tableQueryResult?.data?.data.map((
                        { retries }) => ({ "text": retries, "value": retries})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { retries: string; }) => record.retries.startsWith(value)}
                    title="Retries" 
                    dataIndex="retries" 
                    render={(_: any, record: { retries: any; }) => {
                        return (
                            <TagField
                                color={record?.retries > 3 ? "red" : record?.retries > 0 ? "yellow" : ""}
                                value={`${
                                    record?.retries
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    sorter={ (a: any, b: any) => a.message_id - b.message_id}
                    filters={tableQueryResult?.data?.data.map((
                        { message_id }) => ({ "text": message_id, "value": message_id})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { message_id: string; }) => record.message_id.startsWith(value)}
                    title="Message id" 
                    dataIndex="message_id" 
                    render={(_: any, record: { message_id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.message_id
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IWorkItem>
                    title="Actions"
                    dataIndex="arn"
                    render={(_: any, record: {
                        table: string; lock_value: string; 
}) => (
                        <Space>
                            <ShowButton
                                size="small"
                                recordItemId= {record.table + "_" + record.lock_value }
                            />
                            <EditButton
                                size="small"
                                recordItemId= {record.table + "_" + record.lock_value}
                            />
                            <DeleteButton
                                size="small"
                                recordItemId= {record.table + "_" + record.lock_value}
                            />                            
                        </Space>
                    )}
                />        
            </Table>

            <StepFunctionModal />
        </List>
    );
};



