import React, { useState } from 'react';
import { Alert, Typography, Space, Collapse, Card, Divider, Tag, Button } from 'antd';
import { InfoCircleOutlined, QuestionCircleOutlined, TeamOutlined, ApartmentOutlined, LinkOutlined, FileSearchOutlined, ClusterOutlined } from '@ant-design/icons';

const { Text, Title, Paragraph } = Typography;
const { Panel } = Collapse;

interface WorkItemInfoProps {
  record: any;
}

export const WorkItemInfo: React.FC<WorkItemInfoProps> = ({ record }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card 
      style={{ marginBottom: 16 }}
      title={
        <Space>
          <InfoCircleOutlined />
          <span>Work Item Information</span>
        </Space>
      }
      extra={
        <Button 
          type="link" 
          icon={expanded ? <InfoCircleOutlined /> : <QuestionCircleOutlined />} 
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "Hide Details" : "Learn More"}
        </Button>
      }
    >
      {expanded ? (
        <div>
          <Paragraph>
            <Text strong>What is a Work Item?</Text> A work item represents an individual blockchain transaction 
            belonging to a specific organization, tenant, and user. Each work item is processed through a Step Function 
            workflow and can be part of larger processing batches.
          </Paragraph>

          <Collapse ghost>
            <Panel header={<Text strong><TeamOutlined /> User Sharing & Ownership</Text>} key="1">
              <Paragraph>
                <ul>
                  <li>Each work item is associated with a specific <Tag color="blue">org_id</Tag>, <Tag color="green">tenant_id</Tag>, and <Tag color="purple">user_id</Tag></li>
                  <li>When a transaction is shared with multiple users, the backend creates assignments for all users who have access</li>
                  <li>This allows multiple users within the same organization to view and manage shared transactions</li>
                  <li>The primary user who initiated the transaction is typically recorded in the <Text code>user_id</Text> field</li>
                </ul>
              </Paragraph>
            </Panel>

            <Panel header={<Text strong><ApartmentOutlined /> Step Function Processing</Text>} key="2">
              <Paragraph>
                <ul>
                  <li>Each work item is processed through an AWS Step Function workflow</li>
                  <li>The Step Function orchestrates various stages: pre-processing, processing, and post-processing</li>
                  <li>The current status of processing is tracked in the <Text code>step_function_status</Text> field</li>
                  <li>Step Functions may use Map Runs to process multiple items in parallel batches</li>
                </ul>
              </Paragraph>
            </Panel>

            <Panel header={<Text strong><LinkOutlined /> Include IDs & Relationships</Text>} key="3">
              <Paragraph>
                <ul>
                  <li>When an integration requests additional data, it creates "includes" with unique <Text code>include_id</Text> values</li>
                  <li>Multiple work items can share the same <Text code>include_id</Text> when they're part of the same data request</li>
                  <li>A single Step Function execution can process multiple <Text code>include_id</Text>s, especially when using Map Runs for batch processing</li>
                  <li>The relationship between work items and includes is tracked through <Text code>lock_key</Text> and <Text code>lock_value</Text> fields</li>
                </ul>
              </Paragraph>
            </Panel>

            <Panel header={<Text strong><ClusterOutlined /> Batch Processing</Text>} key="4">
              <Paragraph>
                <ul>
                  <li>For efficiency, transactions are often processed in batches</li>
                  <li>Map Runs in Step Functions allow parallel processing of multiple items</li>
                  <li>Each batch may have its own execution ARN and processing status</li>
                  <li>Activity logs track the progress of each item through the processing pipeline</li>
                </ul>
              </Paragraph>
            </Panel>
          </Collapse>

          <Divider />

          <Paragraph type="secondary">
            <InfoCircleOutlined /> This work item view provides detailed information about a single transaction, 
            its processing status, related includes, step function execution details, and activity logs.
          </Paragraph>
        </div>
      ) : (
        <Alert
          message={
            <Space>
              <Text>This work item represents a blockchain transaction being processed through our system.</Text>
              <Button type="link" size="small" onClick={() => setExpanded(true)}>Learn more about work items</Button>
            </Space>
          }
          type="info"
          showIcon
        />
      )}
    </Card>
  );
}; 